import React from "react";
import { useState } from "react";
import EachVideos from "./EachVideos";

const VideosCards = (props) => {
  const { elm } = props;
  const [imageClk, setImgClk] = useState(false);

  const imgClk = (e) => {
    const a = document.getElementsByTagName("body");
    a[0].style.overflowY = "hidden";
    videopause();
    pauseAllVideos();
    e.target.play();
    setImgClk((p) => !p);
  };

  const videopause = () => {
    const playVideo = document.querySelectorAll(".MyVideo");
    playVideo.forEach((e) => e.pause());
  };

  const closeClk = () => {
    const a = document.getElementsByTagName("body");
    a[0].style.overflowY = "scroll";
    setImgClk((p) => !p);
    videopause();
  };

  function pauseAllVideos() {
    const videos = document.querySelectorAll(".videoPause");

    for (let i = 0; i < videos.length; i++) {
      if (videos[i] instanceof HTMLVideoElement && !videos[i].paused) {
        videos[i].pause();
      }
    }
  }

  return (
    <>
      <div className="w-[350px] inline">
        <div id="EaachImage" className="flex flex-wrap">
          <div
            data-aos="fade-down"
            className="w-full p-1 md:p-2 select-none relative !ml-0 !pl-0"
          >
            <div className="div w-[350px] h-[300px] bg-black overflow-hidden">
              <video
                controls
                width="320"
                height="240"
                className="videoPause block bg-black lg:opacity-60 w-full h-full shadow duration-500 hover:opacity-40 cursor-pointer hover:scale-105 object-contain object-center mb-3 rounded-lg m-auto"
                onClick={imgClk}
              >
                <source
                  src={elm.gallery_video}
                  type="video/mp4"
                  className="block bg-black lg:opacity-60 w-full h-full shadow duration-500 hover:opacity-40 cursor-pointer hover:scale-105 object-contain object-center mb-3 rounded-lg m-auto"
                />
              </video>
            </div>
          </div>
          {imageClk && (
            <div
              id="myModal"
              className="modal !pt-0 overflow-hidden !w-full !h-full !flex !justify-center !items-center"
            >
              <span
                className="close !z-[100000000000000] select-none !top-1 "
                onClick={closeClk}
              >
                &times;
              </span>
              <EachVideos id={elm.id} url={elm.gallery_video} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VideosCards;
