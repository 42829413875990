import React, { useContext, useEffect } from "react";
import EachNewsCards from "./EachNewsCards";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const NewsEvent = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      (e.sub_category.toLowerCase() === "news&event" ||
        e.sub_category.toLowerCase() === "slug-4")
  );
  const sortedData = data.sort((a, b) => b.id - a.id);

  return (
    sortedData.length > 0 && (
      <>
        <div className="md:h-[150px] h-[70px] bg-[#E6E6E650] mb-2"></div>
        <div className=" min-h-[40vh] w-full flex justify-center">
          <div className="w-[90%] relative flex justify-center items-center flex-col float-right h-full">
            <Link
              to={"/extensions/news-and-events"}
              className="absolute right-7 md:top-7 top-20  bg-transparent text-black p-[4px] mt-2 border tracking-wide hover:bg-[#041962] hover:text-white md:px-4 px-3 text-sm md:text-base duration-150 rounded"
            >
              View More
            </Link>
            <h2 className="my-7 mb-20 md:mb-7 text-4xl font-semibold border-b  font-abhaya text-[#041962] tracking-wide">
              News & Events
            </h2>
            <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 h-full">
              {sortedData.length > 0 &&
                sortedData.slice(0, 4).map((e) => {
                  return <EachNewsCards key={e.id} elm={e} />;
                })}
            </div>
          </div>
        </div>
        <div className="h-[2px] w-full mt-10 bg-[#C9C9C9]" />
      </>
    )
  );
};

export default NewsEvent;
