import { useState } from "react";
import SyllabusContext from "./SyllabusContext";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const SyllabusState = (props) => {
  let formData = new FormData();
  const DomainUrl = Domain();
  const [render, setRender] = useState(false);
  const [noteData, setNoteData] = useState([]);

  // post syllabus data
  const postsyllabus = async (data) => {
    formData.append("sem_type", data.className);
    formData.append("year_type", data.yearcls);
    formData.append("stream_name", data.category);
    formData.append("subject_code", data.courseCode);
    formData.append("credit_hours", data.courseHr);
    formData.append("course_title", data.subName);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/syllabus/syllabus`,
        formData,
        {
          headers,
        }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">syllabus successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.subject) {
          toast.error(
            <div className="text-[14px]">{e.response.data.subject}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // gets syllabus data
  const getsyllabus = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/syllabus/syllabus`,
        {
          headers,
        }
      );
      const data = await response.data;
      setNoteData(data);
    } catch (e) {}
  };

  //for update syllabus
  const updatesyllabus = async (data, id) => {
    formData.append("sem_type", data.className);
    formData.append("year_type", data.yearcls);
    formData.append("stream_name", data.category);
    formData.append("subject_code", data.courseCode);
    formData.append("credit_hours", data.courseHr);
    formData.append("course_title", data.subName);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/syllabus/syllabus/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        setRender(!render);
        toast.success(
          <div className="deleteToast text-[15px]">
            syllabus successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };

  //   for delete syllabus
  const deletesyllabus = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/syllabus/syllabus/${id}`,
        { headers }
      );
      if (response.status) {
        setRender(!render);
        toast.warning(
          <div className="deleteToast text-[15px]">
            syllabus successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };

  return (
    <SyllabusContext.Provider
      value={{
        postsyllabus,
        getsyllabus,
        deletesyllabus,
        updatesyllabus,
        noteData,
        render,
      }}
    >
      {props.children}
    </SyllabusContext.Provider>
  );
};

export default SyllabusState;
