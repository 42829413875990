import React, { useEffect } from "react";
import VideosContext from "./VideosContext";
import { toast } from "react-toastify";
import { useState } from "react";
import Domain from "../Domain";
import axios from "axios";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const VideosState = (props) => {
  // to show formData
  let formData = new FormData();

  const DomainUrl = Domain();
  const [videosData, setvideosData] = useState([]);
  const [subvideosData, setSubvideosData] = useState([]);
  const [render, setRender] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(null);

  useEffect(() => {
    if (uploadProgress === 100) {
      setUploadProgress(null);
    }
  }, [uploadProgress]);

  // fetch videos data from backend
  const getvideos = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/gallery/video-gallery`,
        { headers }
      );
      const data = await response.data;
      setvideosData(data);
    } catch (e) {}
  };

  // post videos data
  const postvideos = async (data) => {
    formData.append("title_name", data.title);
    formData.append("gallery_video", data.image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/gallery/video-gallery`,

        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Videos successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.title_name) {
          toast.error(
            <div className="text-[14px]"> {e.response.data.title_name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // delete videos data
  const deletevideos = async (id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/gallery/video-gallery/${id}`,
        { headers }
      );
      if (response.status) {
        setRender(!render);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Video successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.title_name) {
          toast.error(
            <div className="text-[14px]"> {e.response.data.title_name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // update videos data
  const updatevideosData = async (data, id) => {
    formData.append("title_name", data.title_name);
    formData.append("gallery_video", data.videos_image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/gallery/video-gallery/${id}`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Video successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.title_name) {
          toast.error(
            <div className="text-[14px">{e.response.data.title_name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.gallery_video) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.gallery_video[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // ================================ for videos =================================
  const [subvideosrender, setSubvideosRender] = useState(false);

  // fetch videos data from backend
  const getSubvideos = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-video-gallery`,
        { headers }
      );
      const data = await response.data;
      setSubvideosData(data);
    } catch (e) {}
  };

  // post videos data
  const postSubvideos = async (data) => {
    try {
      for (let i = 0; i < data.image.length; i++) {
        formData.append("sub_gallery_video", data.image[i]);
        formData.append("video_gallery_id", data.videos_id);
        const headers = await {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
        };
        const res = await axios.post(
          `${DomainUrl}citizen_cms/api/v1/gallery/sub-video-gallery`,
          formData,
          { headers }
        );
        if (res.status) {
          toast.success(
            <div className="text-[15px]">Video successfully created</div>,
            { icon: <FiCheckSquare className="text-2xl" /> }
          );
          setSubvideosRender(!subvideosrender);
        } else {
          toast.error(<div className="text-[14px]">An error occured!</div>, {
            icon: <BiErrorCircle className="text-xl " />,
          });
        }
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.sub_gallery_video) {
          toast.error(
            <div className="text-[14px]">
              {" "}
              {e.response.data.sub_gallery_video[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // delete videos data
  const deleteSubvideos = async (id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-video-gallery/${id}`,
        { headers }
      );
      if (response.status) {
        setSubvideosRender(!subvideosrender);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Video successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.sub_gallery_video) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.sub_gallery_video[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  // update videos data
  const updateSubvideosData = async (data, id) => {
    formData.append("title_name", data.title_name);
    formData.append("sub_gallery_video", data.image);
    formData.append("video_gallery_id", data.videos_id);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-video-gallery/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Video successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setSubvideosRender(!subvideosrender);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.sub_gallery_video) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.sub_gallery_video[0]}
            </div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  return (
    <VideosContext.Provider
      value={{
        videosData,
        getvideos,
        postvideos,
        deletevideos,
        updatevideosData,
        getSubvideos,
        postSubvideos,
        deleteSubvideos,
        updateSubvideosData,
        render,
        subvideosData,
        subvideosrender,
        uploadProgress,
      }}
    >
      {props.children}
    </VideosContext.Provider>
  );
};

export default VideosState;
