import React, { useEffect, useState } from "react";
import BlogContext from "./BlogContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const BlogState = (props) => {
  let formData = new FormData();
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(null);

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        setUploadProgress(null);
      }, 1000);
    }
    if (uploadProgress === 0) {
      setUploadProgress(1);
    }
  }, [uploadProgress]);
  // for blog get request
  // const getBlogs = async () => {
  //     const response = await axios.get(`${DomainUrl}api/v1/blog/get-blog`)
  //     const data = await response.data;
  //     setData(data);
  // }
  const getBlogs = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/blog/blog`,
        {
          headers,
        }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  // for blog post request
  const postblog = async (data) => {
    formData.append("blog_title", data.title);
    formData.append("blog_post", data.dis);
    formData.append("image", data.image);
    formData.append("category", data.category);
    formData.append("category_key", data.category_key);
    formData.append("sub_category_key", data.sub_category_key);
    formData.append("author_name", data.author_name);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/blog/blog`,
        formData,
        {
          headers,
          onUploadProgress,
        }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Blog successfully added</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data?.author_name[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  //   for delete blog
  const deleteblog = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/blog/blog/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Blog successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.author_name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //for update blog
  const Updateblog = async (data, id) => {
    formData.append("blog_title", data.title);
    formData.append("blog_post", data.dis);
    formData.append("image", data.image);
    formData.append("category", data.category);
    formData.append("category_key", data.category_key);
    formData.append("sub_category_key", data.sub_category_key);
    formData.append("author_name", data.author_name);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/blog/blog/${id}`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Blog successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };
  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  return (
    <>
      <BlogContext.Provider
        value={{
          data,
          postblog,
          getBlogs,
          deleteblog,
          Updateblog,
          renderData,
          uploadProgress,
        }}
      >
        {props.children}
      </BlogContext.Provider>
    </>
  );
};

export default BlogState;
