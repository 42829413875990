import React from "react";
import { Link } from "react-router-dom";

const EachTestimonialContent = (props) => {
  const data = props.data;
  return (
    <div className="w-full flex items-center justify-center flex-col h-full pb-12">
      <div className="w-[120px] h-[120px] mt-3 bg-white shadow rounded-full flex justify-center items-center">
        <img
          src={data.image}
          alt="person"
          className="w-[100px] h-[100px] rounded-full object-cover shadow "
          loading="lazy"
        />
      </div>
      <p className="mt-3 text-xl font-semibold tracking-wide font-abhaya">
        {data.author_name}
      </p>
      <h3 className="md:text-xl text-lg md:mt-5 mt-2 font-semibold tracking-wide text-center md:text-start">
        {data.blog_title}
      </h3>
      <div className="w-[90%] md:w-[60%] m-auto text-center md:text-base text-sm mt-2 tracking-wide">
        <div
          className="sliderDynamic tracking-wide inline"
          dangerouslySetInnerHTML={{
            __html: `${
              data?.blog_post.length > 320
                ? data?.blog_post.slice(0, 320) + "..."
                : data?.blog_post
            }`,
          }}
        ></div>
        {data?.blog_post.length > 320 && (
          <Link to={`/home/${data.id}`} className="underline text-[#041962]">
            view more
          </Link>
        )}
      </div>
    </div>
  );
};

export default EachTestimonialContent;
