import axios from "axios";
import React, { useState } from "react";
import { useContext } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { FiCheckSquare } from "react-icons/fi";
import { toast } from "react-toastify";
import ContactContext from "../contact/ContactContext";
import Domain from "../Domain";
import PreRegistrationContext from "../PreRegistration/PreRegistrationContext";
import PublicApiContext from "./PublicApiContext";

const PublicApiState = (props) => {
  const { setRenderData } = useContext(PreRegistrationContext);
  const { setRenderCData } = useContext(ContactContext);
  const DomainUrl = Domain();

  //    for publicApi carousel get request
  const [carouselData, setCarouselData] = useState([]);
  const getCarouselPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/banner/get-banner`
      );
      const data = await response.data;
      setCarouselData(data);
    } catch (e) {}
  };
  //    for publicApi Advertisement get request
  const [advertisementData, setAdvertisementData] = useState([]);
  const getAdvertisementPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/advertisement/get-advertisement`
      );
      const data = await response.data;
      setAdvertisementData(data);
    } catch (e) {}
  };
  //    for publicApi banner get request
  const [bannerdata, setbannerData] = useState([]);
  const getbannerPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/banner/get-banner`
      );
      const data = await response.data;
      setbannerData(data);
    } catch (e) {}
  };
  //    for publicApi blog get request
  const [blogdata, setblogData] = useState([]);
  const getblogPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/blog/get-blog`
      );
      const data = await response.data;
      setblogData(data);
    } catch (e) {}
  };

  //    for publicApi blog one get request
  const [oneblogdata, setOneBlogData] = useState([]);
  const getOneblogPublicApi = async (id) => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/blog/get-blog?id=${id}`
      );
      const data = await response.data;
      setOneBlogData(data);
    } catch (e) {}
  };

  //    for publicApi category get request
  const [categorydata, setcategoryData] = useState([]);
  const getcategoryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/category/get-category`
      );
      const data = await response.data;
      setcategoryData(data);
    } catch (e) {}
  };

  //    for publicApi footer get request
  const [footerdata, setfooterData] = useState([]);
  const footerPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/footer/get-organizationSetup`
      );
      const data = await response.data;
      setfooterData(data);
    } catch (e) {}
  };

  //    for publicApi gallery get request
  const [gallerydata, setgalleryData] = useState([]);
  const galleryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/image-gallery/get-imageGallery`
      );
      const data = await response.data;
      setgalleryData(data);
    } catch (e) {}
  };

  //    for publicApi subGallery get request
  const [subGallerydata, setsubGalleryData] = useState([]);
  const subGalleryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/sub-image-gallery/get-subImageGallery`
      );
      const data = await response.data;
      setsubGalleryData(data);
    } catch (e) {}
  };

  //    for publicApi video get request
  const [videodata, setvideoData] = useState([]);
  const videoPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/video-gallery/get-videoGallery`
      );
      const data = await response.data;
      setvideoData(data);
    } catch (e) {}
  };

  //    for publicApi subVideo get request
  const [subVideodata, setsubVideoData] = useState([]);
  const subVideoPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/sub-video-gallery/get-subVideoGallery`
      );
      const data = await response.data;
      setsubVideoData(data);
    } catch (e) {}
  };

  //    for publicApi newCategory get request
  const [newCategorydata, setnewCategoryData] = useState([]);
  const newCategoryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/category/get-category`
      );
      const data = await response.data;
      setnewCategoryData(data);
    } catch (e) {}
  };

  //    for publicApi subCategory get request
  const [subCategorydata, setsubCategoryData] = useState([]);
  const subCategoryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/category-key/get-categoryKey`
      );
      const data = await response.data;
      setsubCategoryData(data);
    } catch (e) {}
  };

  //    for publicApi subSubCategory get request
  const [subSubCategorydata, setsubSubCategoryData] = useState([]);
  const subSubCategoryPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/sub-category-key/get-subCategoryKey`
      );
      const data = await response.data;
      setsubSubCategoryData(data);
    } catch (e) {}
  };

  //    for publicApi notes get request
  const [notesdata, setnotesData] = useState([]);
  const notesPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/notes/get-notes`
      );
      const data = await response.data;
      setnotesData(data);
    } catch (e) {}
  };

  //    for publicApi notice get request
  const [noticedata, setnoticeData] = useState([]);
  const noticePublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/notice/get-notice`
      );
      const data = await response.data;
      setnoticeData(data);
    } catch (e) {}
  };

  //    for publicApi notice one get request
  const [onenoticedata, setOnenoticeData] = useState([]);
  const onenoticePublicApi = async (id) => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/notice/get-notice?id=${id}`
      );
      const data = await response.data;
      setOnenoticeData(data);
    } catch (e) {}
  };

  //    for publicApi teacher get request
  const [teacherdata, setteacherData] = useState([]);
  const teacherPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/teachers/get-teachers`
      );
      const data = await response.data;
      setteacherData(data);
    } catch (e) {}
  };

  //    for publicApi subjectName get request
  const [subjectNamedata, setsubjectNameData] = useState([]);
  const subjectNamePublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/subject/get-subject`
      );
      const data = await response.data;
      setsubjectNameData(data);
    } catch (e) {}
  };

  const PostContact = async (data) => {
    try {
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/public/public/post-contact`,
        data
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Thank you for contacting us</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderCData((p) => !p);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.phone_no) {
        toast.error(
          <div className="text-[14px]">{e.response.data.phone_no[0]}</div>,
          { icon: <BiErrorCircle className="text-xl " /> }
        );
      }
    }
  };

  const PostRegistration = async (data) => {
    try {
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/public/public/post-pre-registration`,
        data
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Registrated successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData((p) => !p);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.email) {
        toast.error(
          <div className="text-[14px]">{e.response.data.email[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else if (e.response.data.mobile_num) {
        toast.error(
          <div className="text-[14px]">{e.response.data.mobile_num[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //    for publicApi clubs get request
  const [clubsdata, setclubsData] = useState([]);
  const clubsPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/student-club/get-student-club`
      );
      const data = await response.data;
      setclubsData(data);
    } catch (e) {}
  };

  //    for publicApi clubs get request
  const [syllabusdata, setsyllabusData] = useState([]);
  const syllabusPublicApi = async () => {
    try {
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/public/syllabus/get-syllabus`
      );
      const data = await response.data;
      setsyllabusData(data);
    } catch (e) {}
  };

  const [dataLength, setDataLength] = useState(0);

  return (
    <PublicApiContext.Provider
      value={{
        notesPublicApi,
        notesdata,
        subSubCategoryPublicApi,
        subSubCategorydata,
        getCarouselPublicApi,
        carouselData,
        newCategoryPublicApi,
        newCategorydata,
        subCategoryPublicApi,
        subCategorydata,
        footerPublicApi,
        footerdata,
        galleryPublicApi,
        gallerydata,
        getcategoryPublicApi,
        categorydata,
        getAdvertisementPublicApi,
        advertisementData,
        getbannerPublicApi,
        bannerdata,
        subjectNamePublicApi,
        subjectNamedata,
        subGallerydata,
        subGalleryPublicApi,
        subVideodata,
        subVideoPublicApi,
        videodata,
        videoPublicApi,
        getblogPublicApi,
        blogdata,
        noticePublicApi,
        noticedata,
        teacherPublicApi,
        teacherdata,
        PostContact,
        PostRegistration,
        clubsdata,
        clubsPublicApi,
        oneblogdata,
        getOneblogPublicApi,
        onenoticedata,
        onenoticePublicApi,
        dataLength,
        setDataLength,
        syllabusdata,
        syllabusPublicApi,
      }}
    >
      {props.children}
    </PublicApiContext.Provider>
  );
};

export default PublicApiState;
