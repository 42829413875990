import React, { useContext, useEffect, useState } from "react";
import { BsEyeSlashFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import ContactContext from "../../../context/contact/ContactContext";
import ContactModal from "../Modals/ContactModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Contact = () => {
  const { data, getContact, deleteContact, renderData, setRenderCData } =
    useContext(ContactContext);
  const [btnId, setBtnId] = useState("");

  useEffect(() => {
    getContact();
    // eslint-disable-next-line
  }, [renderData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRenderCData((prev) => !prev);
    }, 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id) => {
    setOpen(true);
    setBtnId(id);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [FilteredData, setFilteredData] = useState([]);

  const EyeBtnClk = (id) => {
    const modData = data.filter((e) => e.id === id);
    setFilteredData(modData);
    setModalOpen(true);
  };
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = data.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = data.filter((e) =>
    e.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-full overflow-y-auto mt-3 md:ml-[270px] relative">
        <div className="w-[99%]">
          <table
            border="1"
            className="border  mt-[70px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border p-1" style={{ width: "320px" }}>
                  Name
                </th>
                <th className="border p-1" style={{ width: "310px" }}>
                  Mobile Number
                </th>
                <th className="border p-1" style={{ width: "310px" }}>
                  Email
                </th>
                <th className="border p-1" style={{ width: "300px" }}>
                  Address
                </th>
                <th className="border p-1" style={{ width: "300px" }}>
                  Message
                </th>
                <th className="border p-1" style={{ width: "100px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {data
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[160px] w-[20%]">
                            {e.name}
                          </td>
                          <td className="border px-1 min-w-[110px] w-[15%]">
                            {e.phone_no}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.email}
                          </td>
                          <td className="border px-1 min-w-[150px] w-[15%]">
                            {e.address}
                          </td>
                          <td className="border px-1 min-w-[150px] w-[20%]">
                            {e.message.substring(0, 20) + "..."}
                          </td>
                          <td className="flex items-center justify-center p-1 h-[40px]">
                            <button className="edit actionIcons ">
                              <BsEyeSlashFill
                                className="text-xl text-white icons"
                                onClick={() => EyeBtnClk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "20px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[160px] w-[20%]">
                            {e.name}
                          </td>
                          <td className="border px-1 min-w-[110px] w-[15%]">
                            {e.phone_no}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.email}
                          </td>
                          <td className="border px-1 min-w-[150px] w-[15%]">
                            {e.address}
                          </td>
                          <td className="border px-1 min-w-[150px] w-[20%]">
                            {e.message.substring(0, 20) + "..."}
                          </td>
                          <td className="flex items-center justify-center p-1 h-[40px]">
                            <button className="edit actionIcons ">
                              <BsEyeSlashFill
                                className="text-xl text-white icons"
                                onClick={() => EyeBtnClk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnId}
              deleteFun={deleteContact}
            />
          ) : (
            <></>
          )}
          {data.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {modalOpen ? (
        <ContactModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          FilteredData={FilteredData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Contact;
