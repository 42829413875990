import React from "react";

const EachTeacherCard = (props) => {
  const data = props.data;
  return (
    <>
      <div className="max-w-[340px] w-full min-w-[230px] flex flex-col items-start justify-start">
        <img
          src={data.image}
          alt="teacher"
          className="w-full h-[280px] rounded-sm object-cover"
          loading="lazy"
        />

        <div className="text-lg capitalize text-slate-700 font-semibold !mt-1">
          Name: {data.name}
        </div>
        <div className="font-semibold text-slate-700 capitalize text-sm">
          designation: {data.qualification}
        </div>
      </div>
    </>
  );
};

export default EachTeacherCard;
