import { Button, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { ImCross } from "react-icons/im";
import AdsContext from "../../../context/Advertisement/AdsContext";

const AddsModal = (props) => {
  const { postAds, updateAds } = useContext(AdsContext);
  const { OpenModal, setOpenModal, editableData, btnData, uploaded } = props;
  const [postData, setpostData] = useState({
    title: btnData.name === "updateBtn" ? editableData[0].name : "",
    banner_image: btnData.name === "updateBtn" ? editableData[0].image : "",
  });

  const buttonref = useRef();
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });
  const [imgErrorMsg, setimgErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });
  const [image, setImage] = useState("");
  const titlechange = (e) => {
    setpostData({ ...postData, [e.target.name]: e.target.value });
  };
  const teacherImageChange = (e) => {
    setImage(e.target.files[0]);
    let a = e.target.files[0].name.split(".").pop().toLowerCase();
    if (a !== "png" && a !== "jpg" && a !== "jpeg") {
      setimgErrorMsg({
        status: true,
        msg: "Image extension can only be in png and jpg",
        position: "img",
      });
    } else {
      setimgErrorMsg({ status: false, msg: "", position: "" });
    }
  };
  useEffect(() => {
    if (OpenModal) {
      buttonref.current.click();
    } // eslint-disable-next-line
  }, []);
  if (uploaded === 100) {
    setTimeout(() => {
      buttonref.current?.click();
      setOpenModal(false);
    }, 1000);
  }

  const addbtn = (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    if (postData.title <= 0) {
      setErrorMsg({ status: true, msg: "Please Enter Name", position: "name" });
    } else if (image === null || typeof image !== "object") {
      setErrorMsg({
        status: true,
        msg: "Please choose an Image",
        position: "image",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      if (imgErrorMsg.status === false) {
        if (btnData.name === "updateBtn") {
          const finalData = { ...postData, banner_image: image };
          updateAds(finalData, btnData.id);
        } else {
          const finalData = { ...postData, banner_image: image };
          postAds(finalData, btnData.id);
        }
        // buttonref.current.click();
        // setOpenModal(false);
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary text-black hidden"
        ref={buttonref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {/* Launch demo modal */}
      </button>
      <div
        className="modal fade modal-lg mt-20 "
        id="exampleModal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog flex items-center justify-center">
          <form className="modal-content w-[650px]">
            <div className="modal-header py-4 relative">
              <h5
                className="modal-title ml-[25px] absolute top-5 text-xl tracking-wider font-semibold"
                id="exampleModalLabel"
              >
                {btnData.name !== "updateBtn" ? "Create " : "Edit"}{" "}
                Advertisement
              </h5>
              <ImCross
                className="text-gray-500 absolute right-0 mr-5 cursor-pointer hover:text-gray-800"
                data-bs-dismiss="modal"
                onClick={() => setOpenModal(false)}
              />
            </div>

            <div className="relative w-[1250px] ml-[40px] mt-[30px]">
              <TextField
                id="outlined-basic"
                className="w-[45%]"
                name="title"
                label="Title"
                value={postData.title}
                onChange={titlechange}
                variant="outlined"
              />
            </div>
            {errorMsg.position === "name" ? (
              <p className="text-red-500 w-[96%] text-xs ml-16 -mt-0">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}

            <div className="relative w-[1250px] ml-[40px] mt-[30px] mb-[30px]">
              <input
                className="form-control form-control-lg text w-[45%] rounded-1 shadow-2xl bg-slate-200"
                name="image"
                id="formFileLg"
                onChange={teacherImageChange}
                type="file"
                style={{ fontSize: "16px", paddingTop: "15px" }}
              />
            </div>
            {errorMsg.position === "image" ? (
              <p className="text-red-500 w-[96%] text-xs ml-16 -mt-6 mb-2">
                **{errorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            {imgErrorMsg.position === "img" ? (
              <p className="text-red-500 w-[96%] text-xs ml-16 -mt-6 mb-2">
                **{imgErrorMsg.msg}**
              </p>
            ) : (
              ""
            )}
            {uploaded && uploaded > 0 && (
              <div className="w-[85%] m-auto">
                <div className="progress my-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={uploaded}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${uploaded}%` }}
                  >
                    {`${uploaded}%`}
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer pr-[50px]">
              <Button
                type="submit"
                variant="contained"
                size="small"
                className="mb-2 mt-1 !capitalize"
                onClick={addbtn}
              >
                {" "}
                {btnData.name === "updateBtn" ? "Update" : "Create"}{" "}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddsModal;
