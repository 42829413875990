import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsFillCircleFill, BsFileEarmarkPostFill } from "react-icons/bs";
import {
  GiKnightBanner,
  GiArchiveRegister,
  GiStabbedNote,
  GiWhiteBook,
} from "react-icons/gi";
import { RiAdvertisementFill, RiGalleryFill } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MdPermContactCalendar } from "react-icons/md";
// import { SiClubhouse } from "react-icons/si";
import { useEffect } from "react";

const SideNav = (props) => {
  const { hamburgerClk } = props;
  const [subCategoryShow, setSubCategoryShow] = useState(false);
  const [multimedia, setmultimedia] = useState(false);
  const [notes, setNotes] = useState(false);
  const location = useLocation().pathname;
  const Navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("Authorization")) {
      Navigate("/admin/login");
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <aside
        className={
          hamburgerClk
            ? "z-50 flex-shrink-0 h-[90vh] hidden w-64 overflow-y-auto bg-[#004080] md:block shadow-md mt-[.5px] fixed"
            : "z-50 flex-shrink-0 block w-64 overflow-y-auto bg-[#004080] md:block shadow-md mt-[.5px] fixed"
        }
        style={{
          background:
            " linear-gradient(266deg, rgba(28,59,90,1) 8%, rgba(10,34,58,1) 71%)",
        }}
      >
        <div className="py-4 text-gray-300">
          <Link
            className="ml-6 text-lg md:hidden font-bold text-gray-200 tracking-widest"
            to="/"
          >
            Citizen College
          </Link>
          <ul className="mt-2">
            <li className="relative px-6 py-[6px] ">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]   p-2 rounded-md  w-full text-sm font-medium transition-colors duration-150  hover:text-white ${
                  location === "/admin/dashboard" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/dashboard"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span className="ml-4">Dashboard</span>
              </Link>
            </li>
          </ul>
          <ul>
            <li
              className="px-6 py-[6px] flex items-center"
              onClick={() => setSubCategoryShow(!subCategoryShow)}
            >
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5] w-full p-2 rounded-md relative text-sm font-medium transition-colors duration-150  hover:text-white ${
                  location === "/admin/sub-category" ||
                  location === "/admin/subsubcategory" ||
                  location === "/admin/categories"
                    ? `bg-[#4b4a4ac5]`
                    : ``
                }`}
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
                </svg>
                <span className="ml-4">Categories</span>
                {subCategoryShow ? (
                  <IoIosArrowUp className="text-lg cursor-pointer absolute right-4" />
                ) : (
                  <IoIosArrowDown className="text-lg cursor-pointer absolute right-4" />
                )}
              </Link>
            </li>
            <li
              className={
                subCategoryShow
                  ? `relative px-6 pb-2 ml-3 block`
                  : `relative px-6 pb-2 ml-4 hidden`
              }
            >
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/categories" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/categories"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-1 text-[13px]">Categories</span>
              </Link>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/sub-category" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/sub-category"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-1 text-[13px]">Sub-Categories</span>
              </Link>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/subsubcategory" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/subsubcategory"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-1 text-[13px]">Sub-Sub-Category</span>
              </Link>
            </li>
            {/* <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/footer" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/footer"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"></path>
                </svg>
                <span className="ml-4">Footer</span>
              </Link>
            </li> */}
            <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/banner" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/banner"
              >
                <GiKnightBanner className="text-xl" />
                <span className="ml-4">Banner</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/teachers" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/teachers"
              >
                <FaChalkboardTeacher className="text-xl" />
                <span className="ml-4">Teachers</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <span
                className="absolute inset-y-0 left-0 w-1 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/preregistration" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/preregistration"
              >
                <GiArchiveRegister className="text-xl" />
                <span className="ml-4">Pre-registration</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <span
                className="absolute inset-y-0 left-0 w-1 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/contact" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/contact"
              >
                <MdPermContactCalendar className="text-xl" />
                <span className="ml-4">Contact</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/blogs" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/blogs"
              >
                <BsFileEarmarkPostFill className="text-[19px]" />
                <span className="ml-4">Blogs</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/advertisement" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/advertisement"
              >
                <RiAdvertisementFill className="text-[19px]" />
                <span className="ml-4">Advertisement</span>
              </Link>
            </li>
            <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/notice" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/notice"
              >
                <GiStabbedNote className="text-[19px]" />
                <span className="ml-4">Notice</span>
              </Link>
            </li>
            {/* <li className="relative px-6 py-[6px]">
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/clubs" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/clubs"
              >
                <SiClubhouse className="text-[19px]" />
                <span className="ml-4">Clubs</span>
              </Link>
            </li> */}
            <li
              className="px-6 py-[6px] flex items-center"
              onClick={() => setmultimedia(!multimedia)}
            >
              <Link
                to="/admin/gallery"
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full p-2 rounded-md relative text-sm font-medium transition-colors duration-150  hover:text-white ${
                  location === "/admin/gallery" || location === "/admin/videos"
                    ? `bg-[#4b4a4ac5]`
                    : ``
                }`}
              >
                <RiGalleryFill className="text-[19px]" />
                <span className="ml-4">Gallery</span>
                {multimedia ? (
                  <IoIosArrowUp className="text-lg cursor-pointer absolute right-4" />
                ) : (
                  <IoIosArrowDown className="text-lg cursor-pointer absolute right-4" />
                )}
              </Link>
            </li>
            <li
              className={
                multimedia
                  ? `relative px-6 pb-2 ml-3 block`
                  : `relative px-6 pb-2 ml-4 hidden`
              }
            >
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/gallery" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/gallery"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-4 text-[13px]">Photos</span>
              </Link>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/videos" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/videos"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-4 text-[13px]">Videos</span>
              </Link>
            </li>

            <li
              className="px-6 py-[6px] flex items-center"
              onClick={() => setNotes((p) => !p)}
            >
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full p-2 rounded-md relative text-sm font-medium transition-colors duration-150  hover:text-white ${
                  location === "/admin/notes" ||
                  location === "/admin/subject-name" ||
                  location === "/admin/syllabus"
                    ? `bg-[#4b4a4ac5]`
                    : ``
                }`}
              >
                <GiWhiteBook className="text-[19px]" />
                <span className="ml-4">Notes</span>
                {notes ? (
                  <IoIosArrowUp className="text-lg cursor-pointer absolute right-4" />
                ) : (
                  <IoIosArrowDown className="text-lg cursor-pointer absolute right-4" />
                )}
              </Link>
            </li>
            <li
              className={
                notes
                  ? `relative px-6 pb-2 ml-3 block`
                  : `relative px-6 pb-2 ml-4 hidden`
              }
            >
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/subject-name" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/subject-name"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-4 text-[13px]">Subject</span>
              </Link>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/notes" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/notes"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-4 text-[13px]">Notes</span>
              </Link>
              <Link
                className={`inline-flex items-center hover:bg-[#4b4a4ac5]  w-full text-sm font-medium transition-colors duration-150  p-2 rounded-md hover:text-white ${
                  location === "/admin/syllabus" ? `bg-[#4b4a4ac5]` : ``
                }`}
                to="/admin/syllabus"
              >
                <BsFillCircleFill className="text-[5px] mr-1 ml-5" />
                <span className="ml-4 text-[13px]">Syllabus</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideNav;
