import React, { useContext, useEffect } from "react";
import EachNoticeCatd from "./EachNoticeCatd";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Notice = () => {
  const { noticePublicApi, noticedata } = useContext(PublicApiContext);
  useEffect(() => {
    noticePublicApi();
    // eslint-disable-next-line
  }, []);
  const allActiveData = noticedata.filter((e) => e.active === true);
  const reversedNoticedata = allActiveData.sort((a, b) => b.id - a.id);
  return (
    reversedNoticedata.length > 0 && (
      <>
        <div className="min-h-[40vh] w-full flex justify-center mt-5">
          <div className="w-[90%] relative flex justify-center items-center flex-col float-right h-full">
            <Link
              to={"/extensions/notice"}
              className="absolute right-7 md:top-7 top-20 bg-transparent text-black p-[4px] mt-2 border tracking-wide hover:bg-[#041962] hover:text-white md:px-4 px-3 text-sm md:text-base duration-150 rounded"
            >
              View More
            </Link>
            <h2 className="my-7 mb-20 md:mb-7 text-4xl  font-semibold border-b  font-abhaya text-[#041962] tracking-wide">
              Notice
            </h2>
            <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 h-full">
              {reversedNoticedata.slice(0, 4).map((e) => (
                <EachNoticeCatd data={e} key={e.id} />
              ))}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Notice;
