import React, { useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { ImCross } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import GalleryContext from "../../../context/Gallery/GalleryContext";
import "../css/Post.css";

const SubGalleryModal = (props) => {
  const { deleteSubgallery } = useContext(GalleryContext);
  const Btnref = useRef();
  const { setModalOpen, FilteredData } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(FilteredData[0].image_gallery_id);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    Btnref.current.click();
  }, []);
  const deletesubGalleryFun = (id) => {
    deleteSubgallery(id);
    const afterDeleteData = data.filter((e) => e.id !== id);
    setData(afterDeleteData);
  };
  return (
    <div>
      <button
        type="button"
        className="btn btn-primary hidden"
        ref={Btnref}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      ></button>
      <div
        className="modal fade mt-[10px] modal-lg"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog relative">
          <div className="modal-content p-5 relative flex items-center justify-center min-h-[400px]">
            <ImCross
              className="text-gray-500 absolute right-5 top-4 cursor-pointer hover:text-gray-800"
              data-bs-dismiss="modal"
              onClick={() => setModalOpen(false)}
            />
            <div className="mt-1 flex gap-3 flex-col ml-5">
              <p className="text-xl font-semibold tracking-wider text-center -mt-10 mb-2 underline uppercase">
                {FilteredData[0].title_name}
              </p>
              <div className="container">
                <div className="row">
                  {data.map((e) => {
                    return (
                      <div
                        className="col-lg-3 w-[200px] h-[120px] relative m-2 flex justify-center items-center bg-black imageDiv"
                        key={e.id}
                      >
                        <img
                          loading="lazy"
                          src={e.sub_gallery_image}
                          alt=""
                          className="absolute  hover:bg-[#2b1b1bc9] top-0 bottom-0 hover:opacity-40 right-0 left-0 m-auto w-[200px] h-[120px] object-cover opacity-75"
                        />
                        <MdDelete
                          className="text-2xl text-white z-30 cursor-pointer hover:scale-110 showBtn"
                          onClick={() => deletesubGalleryFun(e.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubGalleryModal;
