import React from "react";
import GalleryContext from "./GalleryContext";
import { toast } from "react-toastify";
import { useState } from "react";
import Domain from "../Domain";
import axios from "axios";
import { useEffect } from "react";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const GalleryState = (props) => {
  // to show formData
  let formData = new FormData();

  const DomainUrl = Domain();
  const [galleryData, setGalleryData] = useState([]);
  const [subGalleryData, setSubGalleryData] = useState([]);
  const [render, setRender] = useState(false);
  const [galleryRender, setGalleryRender] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRender((p) => !p);
    }, 7000);
  }, [galleryRender]);

  // fetch gallery data from backend
  const getGallery = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/gallery/image-gallery`,
        { headers }
      );
      const data = await response.data;
      setGalleryData(data);
    } catch (e) {}
  };

  // post gallery data
  const postgallery = async (data) => {
    formData.append("title_name", data.title);
    formData.append("gallery_image", data.image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/gallery/image-gallery`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Photo successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.title_name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.title_name}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.gallery_image) {
          toast.error(
            <div className="text-[14px]">
              {e.response.data.gallery_image[0]}
            </div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // delete gallery data
  const deletegallery = async (id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/gallery/image-gallery/${id}`,
        { headers }
      );
      if (response.status) {
        setRender(!render);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Photo successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.title_name}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // update gallery data
  const updategalleryData = async (data, id) => {
    formData.append("title_name", data.title_name);
    formData.append("gallery_image", data.gallery_image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/gallery/image-gallery/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Photo successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.title_name}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // ================================ for Gallery =================================
  const [subgalleryrender, setSubGalleryRender] = useState(false);

  // fetch gallery data from backend
  const getSubGallery = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-image-gallery`,
        { headers }
      );
      const data = await response.data;
      setSubGalleryData(data);
    } catch (e) {}
  };

  // post subgallery data
  const postSubgallery = async (data) => {
    try {
      for (let i = 0; i < data.image.length; i++) {
        formData.append("sub_gallery_image", data.image[i]);
        formData.append("image_gallery_id", data.gallery_id);
        const headers = await {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
        };
        const res = await axios.post(
          `${DomainUrl}citizen_cms/api/v1/gallery/sub-image-gallery`,
          formData,
          { headers }
        );
        if (res.status) {
          toast.success(
            <div className="text-[15px]">Photos successfully created</div>,
            { icon: <FiCheckSquare className="text-2xl" /> }
          );
          setSubGalleryRender(!subgalleryrender);
          setGalleryRender((p) => !p);
        } else {
          toast.error(<div className="text-[14px]">An error occured!</div>, {
            icon: <BiErrorCircle className="text-xl " />,
          });
        }
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.title_name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.title_name}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // delete subgallery data
  const deleteSubgallery = async (id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-image-gallery/${id}`,
        { headers }
      );
      if (response.status) {
        toast.warning(
          <div className="deleteToast text-[15px]">
            Photo successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]"> {e.response.data.title_name}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // update gallery data
  const updateSubgalleryData = async (data, id) => {
    formData.append("title_name", data.title_name);
    formData.append("sub_gallery_image", data.image);
    formData.append("gallery_id", data.gallery_id);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/gallery/sub-image-gallery/${id}`,
        formData,
        { headers }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Photo successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]"> {e.response.data.title_name}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  return (
    <GalleryContext.Provider
      value={{
        galleryData,
        getGallery,
        postgallery,
        deletegallery,
        updategalleryData,
        getSubGallery,
        postSubgallery,
        deleteSubgallery,
        updateSubgalleryData,
        render,
        subGalleryData,
        subgalleryrender,
        galleryRender,
      }}
    >
      {props.children}
    </GalleryContext.Provider>
  );
};

export default GalleryState;
