import ContactContext from "./ContactContext";
import React, { useState } from "react";
import axios from "axios";
import Domain from "../Domain";
import { toast } from "react-toastify";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

const ContactState = (props) => {
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderCData] = useState(false);
  // for Contact get request
  const getContact = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/contact/contact`,
        {
          headers,
        }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  const PostContact = async (data) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/contact/contact`,
        data,
        {
          headers,
        }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Thank you for contacting us</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderCData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response?.data.phone_no) {
          toast.error(
            <div className="text-[14px]">{e.response.data.phone_no[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  //   for delete Contact
  const deleteContact = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/contact/contact/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderCData(!renderData);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Contact successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.phone_no) {
          toast.error(
            <div className="text-[14px]">{e.response.data.phone_no[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };

  return (
    <ContactContext.Provider
      value={{
        data,
        getContact,
        deleteContact,
        renderData,
        setData,
        setRenderCData,
        PostContact,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};

export default ContactState;
