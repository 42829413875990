import React from "react";
import { useLocation } from "react-router-dom";

const ContentHead = () => {
  const path = useLocation().pathname;

  function convertPathToText(pathname) {
    const pathSegments = pathname
      .split("/")
      .filter((segment) => segment !== "");
    const formattedText = pathSegments.map((segment) => {
      const words = segment.split("-");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    });
    return formattedText;
  }

  const formattedText = convertPathToText(path);

  const makeBBatt = (name) => {
    if (name.toLowerCase() === "bba tt") {
      return "BBA-TT";
    }
    return name;
  };

  return (
    <>
      <div className="h-[60px] md:h-[70px] border-b m-auto !mt-8 flex items-center justify-center text-white text-lg md:text-2xl w-[95%] bg-[#213C70]">
        <p className="border-b border-[#AFAEAE] px-2 uppercase">
          {formattedText[0].toLowerCase() === "academics"
            ? `Academic (${makeBBatt(formattedText[formattedText.length - 1])})`
            : formattedText[formattedText.length - 1]}
        </p>
      </div>
    </>
  );
};

export default ContentHead;
