import React from "react";
import logo from "../../images/logofooter.png";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="w-full bg-[#213C70] min-h-[40vh]">
        <footer className=" text-white py-6">
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            <div className="col-span-1 md:ml-5 sm:col-span-2 md:col-span-1 justify-center items-center flex flex-col">
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo"
                  className="w-[270px] object-contain"
                  style={{ filter: "brightness(100)" }}
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="w-[90%] m-auto bg-slate-200 h-[1px] sm:hidden" />
            <div className="col-span-1 sm:col-span-2 md:col-span-1 md:ml-16 text-center md:!text-start">
              <p className="text-slate-300 text-lg font-abhaya tracking-wide ">
                Programs:
              </p>
              <ul>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/academics/bba"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    BBA
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/academics/bba-tt"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    BBA-TT
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/academics/bca"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    BCA
                  </Link>
                </li>
              </ul>
              <div className="w-[90%] m-auto bg-slate-200 h-[1px] sm:hidden mt-4" />
              <div className="mt-4">
                <p className="text-lg text-slate-300  font-abhaya tracking-wide ">
                  Social Links:
                </p>
                <div className="flex space-x-2 gap-2 justify-center md:justify-start">
                  <Link
                    to="https://www.facebook.com/CitizenCollege"
                    target="blank"
                    href="your-social-link"
                    className="text-white"
                  >
                    <AiFillFacebook className="text-2xl" />
                  </Link>
                  <Link
                    to="https://www.instagram.com/citizen__college/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
                    target="blank"
                    href="your-social-link"
                    className="text-white"
                  >
                    <AiFillInstagram className="text-2xl" />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/in/citizen-college-36498328a/"
                    target="blank"
                    href="your-social-link"
                    className="text-white"
                  >
                    <AiFillLinkedin className="text-2xl" />
                  </Link>
                </div>
              </div>
              <div className="w-[90%] m-auto bg-slate-200 h-[1px] sm:hidden mt-4" />
            </div>
            <div className="col-span-1 md:ml-8 text-center md:!text-start">
              <p className="text-lg text-slate-300 font-abhaya tracking-wide ">
                Quick Links:
              </p>
              <ul>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/about-us/introduction"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    About Us
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/academics/bba"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    Academics
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/extensions/news-and-events"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    News & Events
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/admission"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    Admission
                  </Link>
                </li>
                <li className="text-lg tracking-wide">
                  <Link
                    to="/extensions/notice"
                    className="hover:text-slate-200 text-[.9rem]"
                  >
                    Notice
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-[90%] m-auto bg-slate-200 h-[1px] sm:hidden" />

            <div className="col-span-1 text-center md:!text-start">
              <p className="text-lg text-[#EBEBEB] font-abhaya tracking-wide  ">
                Contact:
              </p>
              <div className="text-[.9rem]">
                P.O Box- 392
                <br />
                Kumaripati, Lalitpur
                <br />
                <div className="mt-0 md:!mt-2"></div>
                Tel : 01-5445684, 5445685
                <br />
                Email : info@citizencollege.edu.np
                <br />
                <div className="mt-0 md:!mt-2"></div>
                <a
                  href="https://www.google.com/maps/place/27%C2%B040'15.9%22N+85%C2%B019'19.0%22E/@27.6710957,85.31938,17z/data=!3m1!4b1!4m4!3m3!8m2!3d27.6710957!4d85.3219549?entry=ttu"
                  target="_blank"
                  className="flex items-center gap-1 text-center justify-center md:justify-start"
                  rel="noreferrer"
                >
                  Find us on Google map
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.4092 1.67199L17.4975 1.66699L17.6059 1.67366L17.6809 1.68699L17.7834 1.71616L17.8725 1.75449L17.9559 1.80199L18.0309 1.85783L18.0992 1.92033L18.1425 1.96949L18.2109 2.06616L18.2542 2.14616C18.2934 2.22949 18.3184 2.31783 18.3292 2.40949L18.3334 2.49783C18.3334 2.56033 18.3267 2.62199 18.3134 2.68116L18.2842 2.78366L12.8409 17.8478C12.7377 18.0725 12.5722 18.2628 12.3641 18.3962C12.1559 18.5295 11.9139 18.6004 11.6667 18.6003C11.4441 18.601 11.225 18.5441 11.0309 18.435C10.8369 18.3259 10.6743 18.1684 10.5592 17.9778L10.505 17.872L7.71171 12.287L2.15337 9.50699C1.94771 9.41293 1.77034 9.26652 1.63902 9.0824C1.50769 8.89828 1.42701 8.68291 1.40504 8.45783L1.40004 8.33366C1.40004 7.86699 1.65087 7.44033 2.10087 7.19199L2.21754 7.13366L17.2317 1.71199L17.32 1.68699L17.4092 1.67199Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="w-[80%] h-[1px] bg-slate-300 m-auto mt-5"></div>
          <p className="md:mt-5 mt-3 text-[.9rem] text-center w-full font-sans tracking-wide ">
            Copyright &copy; {new Date().getFullYear()}. Citizen College. All
            Rights Reserved.
          </p>
        </footer>
      </div>
    </>
  );
};

export default Footer;
