import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import { useContext } from "react";
import TeacherCOntext from "../../../context/Teacher/TeacherCOntext";
import TeachersModal from "../Modals/TeachersModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Theachers = () => {
  const [AddBtnCk, setAddBtnClk] = useState(false);
  const { getTeachers, data, renderData, deleteTeacher, uploadProgress } =
    useContext(TeacherCOntext);
  const [btnData, setBtnData] = useState({ name: "", id: "" });
  const [editableData, setEditableData] = useState([]);
  useEffect(() => {
    getTeachers();
    // eslint-disable-next-line
  }, [renderData]);
  const editbtnclk = (name, id) => {
    setBtnData({ name: name, id: id });
    setAddBtnClk(true);
    const filterdata = data.filter((e) => e.id === id);
    setEditableData(filterdata);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };
  //add Button function
  const AddBtnClick = (name, id) => {
    setBtnData({ name: name, id: id });
    setAddBtnClk(true);
  };
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = data.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = data.filter((e) =>
    e.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );
  return (
    <>
      <div className="h-full overflow-y-auto mt-3 md:ml-[270px] relative">
        <div className="w-[99%]">
          <button
            onClick={() => AddBtnClick("addBtn", -1)}
            className=" addbutton absolute top-5 right-3 rounded-[5px] border px-3 py-2 flex items-center justify-center"
          >
            Add Teacher <BsPlusLg className="mt-0 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border  mt-[70px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr className="border text-center" style={{ height: "50px" }}>
                <th className="border text-sm p-1" style={{ width: "100px" }}>
                  S.N
                </th>
                <th className="border text-sm p-1" style={{ width: "320px" }}>
                  Name
                </th>
                <th className="border text-sm p-1" style={{ width: "210px" }}>
                  Mobile Number
                </th>
                <th className="border text-sm p-1" style={{ width: "310px" }}>
                  Address
                </th>
                <th className="border text-sm p-1" style={{ width: "300px" }}>
                  Email
                </th>
                <th className="border text-sm p-1" style={{ width: "240px" }}>
                  image
                </th>
                <th className="border text-sm p-1" style={{ width: "200px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {
                    // eslint-disable-next-line
                    data
                      .slice(
                        (pagValue - 1) * paginationValue,
                        pagValue * paginationValue
                      )
                      .map((e, index) => {
                        return (
                          <tr
                            style={{ height: "100%" }}
                            key={e.id}
                            className="border text-center border-b-0"
                          >
                            <td className="border p-1 w-[5%]">
                              {index + 1 + (pagValue - 1) * paginationValue}
                            </td>
                            <td className="border p-1 text-sm w-[18%]">
                              {e.name}
                            </td>
                            <td className="border p-1 text-sm w-[16%]">
                              {e.mobile_no}
                            </td>
                            <td className="border p-1 text-sm w-[20%]">
                              {e.address}
                            </td>
                            <td className="border p-1 text-sm w-[20%]">
                              {e.email}
                            </td>
                            <td className="border p-1 text-sm w-[12%]">
                              <img
                                src={e.image}
                                className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                                alt=""
                                loading="lazy"
                              />{" "}
                            </td>
                            <td className="flex p-2 items-center justify-center h-[40px]">
                              <button className="edit actionIcons mt-1">
                                <MdEdit
                                  className="text-lg text-white icons"
                                  onClick={() => editbtnclk("updateBtn", e.id)}
                                />
                              </button>
                              <button className="delete actionIcons mt-1">
                                <MdDelete
                                  className="text-lg text-white icons"
                                  onClick={() => deleteBtnClk(e.id)}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  }
                </>
              ) : (
                <>
                  {
                    // eslint-disable-next-line
                    searchData
                      .slice(
                        (pagValue - 1) * paginationValue,
                        pagValue * paginationValue
                      )
                      .map((e, index) => {
                        return (
                          <tr
                            style={{ height: "100%" }}
                            key={e.id}
                            className="border text-center border-b-0"
                          >
                            <td className="border p-1 w-[5%]">
                              {index + 1 + (pagValue - 1) * paginationValue}
                            </td>
                            <td className="border p-1 text-sm w-[18%]">
                              {e.name}
                            </td>
                            <td className="border p-1 text-sm w-[16%]">
                              {e.mobile_no}
                            </td>
                            <td className="border p-1 text-sm w-[20%]">
                              {e.address}
                            </td>
                            <td className="border p-1 text-sm w-[20%]">
                              {e.email}
                            </td>
                            <td className="border p-1 text-sm w-[12%]">
                              <img
                                src={e.image}
                                className="h-[30px] w-[30px] rounded-full m-auto object-cover"
                                alt=""
                                loading="lazy"
                              />{" "}
                            </td>
                            <td className="flex p-2 items-center justify-center h-[40px]">
                              <button className="edit actionIcons mt-1">
                                <MdEdit
                                  className="text-lg text-white icons"
                                  onClick={() => editbtnclk("updateBtn", e.id)}
                                />
                              </button>
                              <button className="delete actionIcons mt-1">
                                <MdDelete
                                  className="text-lg text-white icons"
                                  onClick={() => deleteBtnClk(e.id)}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  }
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnData.id}
              deleteFun={deleteTeacher}
              // deleteMsg={deleteMsg}
            />
          ) : (
            <></>
          )}
          {data.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {AddBtnCk ? (
          <TeachersModal
            AddBtnCk={AddBtnCk}
            setAddBtnClk={setAddBtnClk}
            btnData={btnData}
            editableData={editableData}
            uploaded={uploadProgress}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default Theachers;
