import React from "react";
import { useState } from "react";
import EachImage from "./EachImage";
import { useContext } from "react";
import { useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const GalleryCards = (props) => {
  const { elements } = props;
  const [imageClk, setImgClk] = useState(false);
  const { subGallerydata, subGalleryPublicApi } = useContext(PublicApiContext);
  const subGalleryData = subGallerydata;

  useEffect(() => {
    subGalleryPublicApi();
    // eslint-disable-next-line
  }, []);
  const Finaldata = subGalleryData.filter(
    (e) => e.image_gallery_id === elements.id
  );
  const imgClk = () => {
    setImgClk(true);
    const a = document.getElementsByTagName("body");
    a[0].style.overflowY = "hidden";
  };

  const closeClk = () => {
    setImgClk(false);
    const a = document.getElementsByTagName("body");
    a[0].style.overflowY = "scroll";
  };

  return (
    <>
      <div className="w-[350px] inline">
        <div id="EaachImage" className="flex flex-wrap">
          <div
            data-aos="fade-down"
            className="w-full p-1 md:p-2 select-none relative !ml-0 !pl-0"
          >
            {Finaldata.length >= 1 ? (
              <div className="div w-[350px] h-[300px] bg-black overflow-hidden">
                <img
                  alt="gallery"
                  className="block bg-black opacity-60 w-full h-full shadow duration-500 hover:opacity-40 cursor-pointer hover:scale-110 object-contain object-center mb-3 rounded-lg m-auto"
                  src={elements.gallery_image}
                  onClick={imgClk}
                  loading="lazy"
                />
              </div>
            ) : (
              <div className="div w-[350px] h-[300px] bg-black overflow-hidden">
                <img
                  alt="gallery"
                  className="block bg-black opacity-60 w-full h-full shadow duration-500 hover:opacity-40 cursor-pointer object-contain hover:scale-110 object-center mb-3 rounded-lg m-auto"
                  src={elements.gallery_image}
                  loading="lazy"
                />
              </div>
            )}
            <div className="absolute bottom-3 flex flex-row items-center justify-around !font-semibold capitalize text-sm w-full text-white/70">
              <div>{elements.title_name}</div>
              <div>Images: {elements.image_gallery_id.length}</div>
            </div>
          </div>
          <div
            id="myModal"
            className="modal p-0 overflow-hidden"
            style={imageClk ? { display: "block" } : { display: "none" }}
          >
            <span
              className="close select-none !top-1 z-[100] "
              onClick={closeClk}
            >
              &times;
            </span>
            <EachImage id={elements.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryCards;
