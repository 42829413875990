import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SideNav from "./SideNav";
import "../Components/css/dashboard.css";
import { Button } from "@mui/material";
import { FaUser } from "react-icons/fa";
import { useContext } from "react";
import DashboardContext from "../../context/dashboard/DashboardContext";
import LoginContext from "../../context/Login/LoginContext";

const Navigation = () => {
  const { DashBoarddata, getDashboardData, renderData } =
    useContext(DashboardContext);
  const Location = useLocation().pathname;
  const { setSearchValaue } = useContext(LoginContext);
  const ref = useRef();
  const [hamburgerClk, setHamBurgerClk] = useState(true);
  const inpfldchange = () => {
    const searchVal = ref.current.value;
    setSearchValaue(searchVal);
  };
  const Navigate = useNavigate();
  useEffect(() => {
    getDashboardData();
    if (!localStorage.getItem("Authorization")) {
      Navigate("/admin/login");
    } // eslint-disable-next-line
  }, [renderData]);
  const [showCard, setShowCard] = useState(false);
  const logoutClk = () => {
    localStorage.removeItem("Authorization");
    Navigate("/admin/login");
    setShowCard(false);
  };
  const lastChar = Location.lastIndexOf("/");
  const LocationWord =
    Location.charAt(0) +
    Location.charAt(1).toUpperCase() +
    Location.substring(2, lastChar + 1) +
    Location.charAt(lastChar + 1).toUpperCase() +
    Location.substring(lastChar + 2);

  var dt = new Date();

  return (
    <>
      {Location === "/login" ? (
        ""
      ) : (
        <>
          <header
            className="py-4  bg-[#004080] z-50 sticky top-0"
            style={{
              boxShadow: "0px 3px 2px 0 #a6a6a6",
              background:
                " linear-gradient(266deg, rgba(28,59,90,1) 8%, rgba(10,34,58,1) 71%)",
            }}
          >
            <div className="flex items-center justify-between h-full px-6 mx-auto relative">
              <Link
                className="ml-6 text-lg hidden md:block mr-5 font-bold text-slate-100 hover:text-slate-100"
                to="/"
              >
                Citizen College
              </Link>

              <button
                className="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-purple"
                aria-label="Menu"
                onClick={() => setHamBurgerClk(!hamburgerClk)}
              >
                <svg
                  className="w-6 h-6 text-white"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <div className="flex justify-center flex-1 lg:mr-32">
                <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
                  <div className="absolute inset-y-0 flex items-center  pl-2">
                    <svg
                      className="w-4 h-4 text-black"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    className="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-blue-100 border-0 rounded-md focus:placeholder-gray-500 focus:bg-blue-50 focus:border-purple-300 focus:outline-none focus:shadow-outline-purple h-10"
                    type="text"
                    placeholder="Search for projects"
                    aria-label="Search"
                    ref={ref}
                    onChange={inpfldchange}
                  />
                </div>
              </div>
              <ul className="flex items-center flex-shrink-0 space-x-6">
                <li className="relative">
                  <button
                    className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-purple"
                    aria-label="Notifications"
                    aria-haspopup="true"
                  >
                    <svg
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                    </svg>
                    <span
                      aria-hidden="true"
                      className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full"
                    ></span>
                  </button>
                  <template x-if="isNotificationsMenuOpen">
                    <ul className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md">
                      <li className="flex">
                        <Link
                          className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <span>Messages</span>
                          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full">
                            13
                          </span>
                        </Link>
                      </li>
                      <li className="flex">
                        <Link
                          className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <span>Sales</span>
                          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-600 bg-red-100 rounded-full">
                            2
                          </span>
                        </Link>
                      </li>
                      <li className="flex">
                        <Link
                          className="inline-flex items-center justify-between w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <span>Alerts</span>
                        </Link>
                      </li>
                    </ul>
                  </template>
                </li>
                <li className="relative">
                  <button
                    className="align-middle rounded-full  w-8 h-8 focus:shadow-outline-purple focus:outline-none border shadow border-white p-[2px] bg-white flex items-center content-center"
                    aria-label="Account"
                    aria-haspopup="true"
                    onClick={() => setShowCard((p) => !p)}
                  >
                    <img
                      className="object-cover rounded-full"
                      src={
                        DashBoarddata.length > 0
                          ? DashBoarddata[DashBoarddata.length - 1].logo
                          : ""
                      }
                      alt=""
                      aria-hidden="true"
                      loading="lazy"
                    />
                  </button>
                  <template x-if="isProfileMenuOpen">
                    <ul
                      className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md"
                      aria-label="submenu"
                    >
                      <li className="flex">
                        <Link
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <svg
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                          </svg>
                          <span>Profile</span>
                        </Link>
                      </li>
                      <li className="flex">
                        <Link
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <svg
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
                            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                          </svg>
                          <span>Settings</span>
                        </Link>
                      </li>
                      <li className="flex">
                        <Link
                          className="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                          to="/"
                        >
                          <svg
                            className="w-4 h-4 mr-3"
                            aria-hidden="true"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                          </svg>
                          <span>Log out</span>
                        </Link>
                      </li>
                    </ul>
                  </template>
                </li>
              </ul>
              <div
                id="logoutCard"
                className={`absolute duration-500 right-1 h-[130px] w-[180px] rounded-sm bg-[#fffffff7] shadow text-white ${
                  showCard ? `top-[80px]` : `-top-[300px]`
                }`}
              >
                <div className="mainContent flex items-center justify-center flex-col h-full p-5 px-0 w-full ">
                  <p className="text-black mb-2 flex text-lg hover:bg-slate-200 w-full m-auto justify-center items-center py-1 cursor-pointer">
                    {" "}
                    <FaUser className="" />{" "}
                    <span
                      className="-mt-[1px] mx-[9px]"
                      onClick={() => Navigate("/")}
                    >
                      Admin
                    </span>
                  </p>
                  <Button
                    className="m-auto p-2 py-1 text-black w-full hover:bg-slate-200"
                    size="small"
                    onClick={logoutClk}
                  >
                    {" "}
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                    </svg>
                    <p className="ml-1 text-lg capitalize">Signout</p>
                  </Button>
                </div>
              </div>
            </div>
          </header>
          <SideNav hamburgerClk={hamburgerClk} />
          <div className="location absolute top-[14vh] left-[280px] h-10 w-[300px]">
            <li className="text-base font-semibold tracking-wider list-none">
              {LocationWord.substring(1)}
            </li>
          </div>
        </>
      )}
      <p className="fixed bg-white bottom-2 left-[300px] text-sm text-[#5c5c5c]">
        {" "}
        Copyright © {dt.getFullYear()}{" "}
        <a
          href="https://iotech.com.np/"
          className="text-[#5c5c5c] hover:text-black"
        >
          Tachyonwave Nepal{" "}
        </a>
      </p>
    </>
  );
};

export default Navigation;
