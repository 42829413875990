import React from "react";

const EachNoticeSlug = (props) => {
  const { data } = props;
  return (
    <>
      {/* <div className="w-full h-full md:py-10 py-5">
        <div className="min-h-[40vh] w-[95%] flex md:gap-2 shadow gap-1 flex-col p-3 md:!p-9 rounded m-auto lg:w-[60%] md:w-[85%]">
          <h3 className="md:text-3xl text-xl font-bold">{data?.notice_name}</h3>
          <h3 className=" text-slate-600  font-semibold">
            {data?.created_date_bs}
          </h3>
          <div
            className="text-sm md:text-base text-justify"
            dangerouslySetInnerHTML={{ __html: data?.notice_post }}
          />
          {data.image && (
            <img
              src={data?.image}
              alt="this is name"
              className="!w-full max-h-[40vh] md:max-h-[70vh] object-cover pt-2"
              loading="lazy"
            />
          )}
        </div>
      </div> */}
      <div className="w-full h-full md:py-10 py-5 ">
        <div className="description-container min-h-[10vh] px-2 pb-3 w-[95%] md:gap-2 shadow md:!p-9 rounded m-auto lg:w-[70%] md:w-[85%]">
          {data.image && (
            <img
              src={data?.image}
              alt="this is name"
              className="p-2 !ml-3 !rounded img-responsive !w-full sm:!w-auto !max-w-[600px] sm:!max-w-[350px] !object-cover floating-image right !m-auto"
              loading="lazy"
            />
          )}
          <h3 className="md:text-3xl text-xl mb-1 font-bold">
            {data?.notice_name}
          </h3>
          <h3 className="md:text-lg text-base text-slate-800 font-semibold">
            {data?.created_date_bs}
          </h3>
          <div
            className="text-sm md:text-base text-justify"
            dangerouslySetInnerHTML={{ __html: data?.notice_post }}
          />
        </div>
      </div>
    </>
  );
};

export default EachNoticeSlug;
