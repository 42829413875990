import React, { useContext, useEffect } from "react";
import ContentHead from "../common/ContentHead";
import PublicApiContext from "../../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Introduction = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "about us" &&
      e.sub_category.toLowerCase() === "introduction" &&
      e.sub_sub_category === null
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData[0];

  return (
    finalData && (
      <>
        <div className="min-h-[60vh] mb-20">
          <ContentHead head={"Introduction"} />
          <div className="w-[95%] xl:w-[80%] flex-col md:flex-row md:h-[400px] overflow-hidden m-auto gap-8 flex !mt-20 justify-center items-center">
            <img
              src={finalData?.image}
              alt="introduction"
              className="min-w-[300px] rounded-xl max-h-[70vh] object-cover shadow h-full min-h-[300px] md:w-[40%] w-[95%] bg-[#AFAEAE40] flex justify-end"
            ></img>
            <div className="w-full h-full">
              <div id="dynamiccourses">
                <h3 className="text-xl font-semibold text-[#4A4A4A] tracking-wide mt-4 mb-1">
                  {finalData?.blog_title}
                </h3>
                <div
                  className="text-justify tracking-wide inline"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      finalData?.blog_post.length > 900
                        ? finalData?.blog_post.slice(0, 900) + "..."
                        : finalData?.blog_post
                    }`,
                  }}
                ></div>
                {finalData?.blog_post.length > 900 && (
                  <Link
                    to={`/home/${finalData.id}`}
                    className="underline text-[#041962]"
                  >
                    view more
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Introduction;
