import { BrowserRouter } from "react-router-dom";
import LoginState from "./context/Login/LoginState";
import CategoryState from "./context/category/CategoryState";
import AdsState from "./context/Advertisement/AdsState";
import FooterState from "./context/footer/FooterState";
import BannerState from "./context/banner/BannerState";
import TeacherState from "./context/Teacher/TeacherState";
import PreRestrationState from "./context/PreRegistration/PreRestrationState";
import NewCategoryState from "./context/NewCategory/NewCategoryState";
import DashboardState from "./context/dashboard/DashboardState";
import BlogState from "./context/Blog/BlogState";
import NoticeState from "./context/notice/NoticeState";
import GalleryState from "./context/Gallery/GalleryState";
import VideosState from "./context/videos/VideosState";
import NotesState from "./context/notes/NotesState";
import Index from "./Admin/Index";
import UIndex from "./User/Index";
import ContactState from "./context/contact/ContactState";
import SubjectNameState from "./context/SubjectName/SubjectNameState";
import PublicApiState from "./context/PublicAPI/PublicApiState";
import ClubsState from "./context/Clubs/ClubsState";
import SyllabusState from "./context/syllabus/SyllabusState";

function App() {
  return (
    <BrowserRouter>
      <LoginState>
        <DashboardState>
          <CategoryState>
            <BlogState>
              <NewCategoryState>
                <FooterState>
                  <VideosState>
                    <AdsState>
                      <BannerState>
                        <TeacherState>
                          <PreRestrationState>
                            <NotesState>
                              <SyllabusState>
                                <NoticeState>
                                  <GalleryState>
                                    <ContactState>
                                      <SubjectNameState>
                                        <PublicApiState>
                                          <ClubsState>
                                            <Index />
                                            <UIndex />
                                          </ClubsState>
                                        </PublicApiState>
                                      </SubjectNameState>
                                    </ContactState>
                                  </GalleryState>
                                </NoticeState>
                              </SyllabusState>
                            </NotesState>
                          </PreRestrationState>
                        </TeacherState>
                      </BannerState>
                    </AdsState>
                  </VideosState>
                </FooterState>
              </NewCategoryState>
            </BlogState>
          </CategoryState>
        </DashboardState>
      </LoginState>
    </BrowserRouter>
  );
}

export default App;
