export const chain = (
  <svg
    width="30"
    height="30"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.9994 7.00006H23.9994C22.8948 7.00006 21.9994 7.89549 21.9994 9.00006V23.0001C21.9994 24.1046 22.8948 25.0001 23.9994 25.0001H33.9994C35.104 25.0001 35.9994 24.1046 35.9994 23.0001V9.00006C35.9994 7.89549 35.104 7.00006 33.9994 7.00006Z"
      stroke="#041962"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M34.0001 32.9997H24.0001C22.8956 32.9997 22.0001 33.8951 22.0001 34.9997V48.9997C22.0001 50.1042 22.8956 50.9997 24.0001 50.9997H34.0001C35.1047 50.9997 36.0001 50.1042 36.0001 48.9997V34.9997C36.0001 33.8951 35.1047 32.9997 34.0001 32.9997Z"
      stroke="#041962"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M28.9998 18.9997L28.9998 38.9995"
      stroke="#041962"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
