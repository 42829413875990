import React from "react";

const SwiperPage = (props) => {
  const data = props.data;
  return (
    <div className="relative h-[90vh] w-full">
      <img
        src={data.banner_image}
        alt="Hero section"
        className="w-full h-full object-cover absolute inset-0 "
        loading="lazy"
      />
      <div className="max-h-[90vh] flex-col w-full bg-[#24242490] absolute inset-0 flex justify-center items-center">
        <p className="text-3xl md:text-5xl w-[70%] md:w-[50%] text-center font-light leading-normal mt-16 capitalize font-poppins text-white tracking-wide">
          {data.title}
        </p>
        <p className="text-xl md:text-3xl w-[70%] md:w-[50%] text-center font-light leading-normal mt-3 capitalize font-poppins text-white tracking-wide">
          {data.slogan}
        </p>
      </div>
      {/* <div className="absolute right-5 bottom-5 tracking-widest text-white font-abhaya text-lg">
        1/2
      </div> */}
    </div>
  );
};

export default SwiperPage;
