import React from "react";

const Comments = (props) => {
  const { blogdata } = props;
  const filterComments = blogdata.filter(
    (e) => e.category_name.toLowerCase() === "studentscomment"
  );
  const data = filterComments[filterComments.length - 1];
  return (
    data && (
      <div className="md:absolute lg:static left-0">
        <div className="md:w-full md:flex-row flex-col md:bg-[#F2F2F2] h-full md:h-[220px] flex justify-start items-center !mt-10 md:rounded-l-full relative w-[95%] m-auto">
          <div className="md:w-[200px] md:h-[200px] max-h-[300px] m-[14px] md:rounded-full overflow-hidden">
            <img
              src={data?.image}
              alt="students"
              className="md:w-[200px] md:h-[200px] max-h-[300px] h-full w-full object-cover"
              loading="lazy"
            />
          </div>
          <div className="w-full text-base pr-2 md:text-sm xl:text-base relative md:max-w-[70%] md:ml-5 min-w-[50%] h-full flex justify-center text-justify gap-1 flex-col">
            <div dangerouslySetInnerHTML={{ __html: data?.blog_post }} />
            <p className="font-semibold">
              {data?.blog_title}
              <br />
              {data?.author_name}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

export default Comments;
