import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import NotesContext from "../../../context/notes/NotesContext";
import NotesModal from "../Modals/NotesModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Notes = () => {
  const { getNotes, deleteNotes, noteData, render, uploadProgress } =
    useContext(NotesContext);
  const [AddBtnCk, setAddBtnClk] = useState(false);
  const [btndata, setBtnData] = useState({ name: "", id: "" });
  const [editData, setEditData] = useState([]);
  const editBtnClk = (id) => {
    setAddBtnClk(true);
    setBtnData({ name: "update", id });
    const data = noteData.filter((e) => e.id === id);
    setEditData(data);
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line
  }, [render]);

  const SubCategoryAddBtnCLk = () => {
    setAddBtnClk(true);
    setBtnData({ name: "addBtn", id: -1 });
  };
  const streamCHeck = (e) => {
    if (e === 1) return "BBA";
    else if (e === 2) return "BBA-TT";
    else return "BCA";
  };

  const checkGradesem = (id) => {
    switch (id) {
      case 1:
        return "1st semester";
      case 2:
        return "2nd semester";
      case 3:
        return "3rd semester";
      case 4:
        return "4th semester";
      case 5:
        return "5th semester";
      case 6:
        return "6th semester";
      case 7:
        return "7th semester";
      case 8:
        return "8th semester";
      default:
        break;
    }
  };
  const checkYear = (id) => {
    switch (id) {
      case 1:
        return "1st Year";
      case 2:
        return "2nd Year";
      case 3:
        return "3rd Year";
      case 4:
        return "4th Year";
      default:
        break;
    }
  };

  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = noteData.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = noteData.filter((e) =>
    e.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-[80vh] overflow-y-auto mt-12 md:ml-[255px]">
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={SubCategoryAddBtnCLk}
          >
            Add Notes
            <BsPlusLg className="mt-1 ml-1 text-[11px]" />
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1 w-[100px]">S.N</th>
                <th className="border p-1">Note Name</th>
                <th className="border p-1">Stream Name</th>
                <th className="border max-w-[50px] p-1">Semester/Year</th>
                <th className="border p-1 w-[5%]">Action</th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {noteData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "15px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            {e.name}
                          </td>
                          <td className="border px-1 min-w-[10px] max-w-[700px] w-[12%]">
                            {streamCHeck(e.subject_stream_type)}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.sem_type > 0
                              ? checkGradesem(e.sem_type)
                              : checkYear(e.year_type)}
                          </td>
                          <td className="px-1 min-w-[100px] w-[6%] p-3 flex justify-center items-center m-auto h-[40px]">
                            <button
                              className="edit actionIcons top-1"
                              onClick={() => editBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons top-1">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          style={{ fontSize: "14px", height: "15px" }}
                          className="border text-center border-b-0"
                          key={e.id}
                        >
                          <td className="border px-1 min-w-[40px] w-[5%]">
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border px-1 min-w-[100px] w-[20%]">
                            {e.name}
                          </td>
                          <td className="border px-1 min-w-[10px] max-w-[700px] w-[12%]">
                            {streamCHeck(e.subject_stream_type)}
                          </td>
                          <td className="border px-1 min-w-[130px] w-[15%]">
                            {e.sem_type > 0
                              ? checkGradesem(e.sem_type)
                              : checkYear(e.year_type)}
                          </td>
                          <td className="px-1 min-w-[100px] w-[6%] p-3 flex justify-center items-center m-auto h-[40px]">
                            <button
                              className="edit actionIcons top-1"
                              onClick={() => editBtnClk(e.id)}
                            >
                              <MdEdit className="text-xl text-white icons" />
                            </button>
                            <button className="delete actionIcons top-1">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>

          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btndata.id}
              deleteFun={deleteNotes}
              // deleteMsg={deleteMsg}
            />
          ) : (
            <></>
          )}
          {noteData.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {AddBtnCk ? (
        <NotesModal
          AddBtnCk={AddBtnCk}
          setAddBtnClk={setAddBtnClk}
          btndata={btndata}
          editData={editData}
          uploaded={uploadProgress}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Notes;
