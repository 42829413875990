import React from "react";
import SubjectNameContext from "./SubjectNameContext";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Domain from "../Domain";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const SubjectNameState = (props) => {
  const DomainUrl = Domain();
  const [render, setRender] = useState(false);
  const [subjectNameData, setsubjectNameData] = useState([]);

  // post subjectName data
  const postsubjectName = async (data) => {
    const PostData =
      data.yearcls !== ""
        ? {
            ...data,
            subject_name: data.subName,
            year_type: Number(data.yearcls),
            stream_name: data.category,
          }
        : {
            ...data,
            subject_name: data.subName,
            sem_type: Number(data.className),
            stream_name: data.category,
          };
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/notes/subject`,
        PostData,
        { headers }
      );
      if (response.status === 201) {
        toast.success(
          <div className="text-[15px]">Subject successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender((p) => !p);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };

  // gets subjectName data
  const getsubjectName = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/notes/subject`,
        {
          headers,
        }
      );
      const data = await response.data;
      setsubjectNameData(data);
    } catch (e) {}
  };

  //for update subjectName
  const updatesubjectName = async (data, id) => {
    const PostData =
      data.yearcls !== ""
        ? {
            ...data,
            subject_name: data.subName,
            year_type: Number(data.yearcls),
            stream_name: data.category,
          }
        : {
            ...data,
            subject_name: data.subName,
            sem_type: Number(data.className),
            stream_name: data.category,
          };
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/notes/subject/${id}`,
        PostData,
        { headers }
      );
      if (response.status) {
        toast.success(
          <div className="text-[15px]">Subject successfully updated</div>,
          { icon: <MdOutlineFileUpload className="text-2xl" /> }
        );
        setRender((p) => !p);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };

  //   for delete subjectName
  const deletesubjectName = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/notes/subject/${id}`,
        { headers }
      );
      if (response.status) {
        setRender((p) => !p);
        toast.warning(
          <div className="deleteToast text-[15px]">
            Subject successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    }
  };
  return (
    <SubjectNameContext.Provider
      value={{
        postsubjectName,
        getsubjectName,
        deletesubjectName,
        updatesubjectName,
        subjectNameData,
        render,
      }}
    >
      {props.children}
    </SubjectNameContext.Provider>
  );
};

export default SubjectNameState;
