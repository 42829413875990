import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Domain from "../Domain";
import AdsContext from "./AdsContext";
import "react-toastify/dist/ReactToastify.css";
import { MdDelete } from "react-icons/md";
import { FiCheckSquare } from "react-icons/fi";
// import { GrUpdate, GrUpgrade, GrUpload } from "react-icons/gr";
import { MdOutlineFileUpload } from "react-icons/md";
import { BiErrorCircle } from "react-icons/bi";

const AdsState = (props) => {
  let formData = new FormData();
  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [renderData, setRenderData] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(null);

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        setUploadProgress(null);
      }, 1000);
    }
    if (uploadProgress === 0) {
      setUploadProgress(1);
    }
  }, [uploadProgress]);
  // gets Ads data
  const getAds = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/advertisement/advertisement`,
        { headers }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  // post Ads data
  const postAds = async (data) => {
    formData.append("name", data.title);
    formData.append("banner_image", data.banner_image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/advertisement/advertisement`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Advertisement successfully created</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRenderData(!renderData);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(<>{e.response.data.name[0]}</>);
      }
    }
  };

  //for update Ads
  const updateAds = async (data, id) => {
    formData.append("name", data.title);
    formData.append("banner_image", data.banner_image);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/advertisement/advertisement/${id}`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        setRenderData(!renderData);
        toast.success(
          <div className="deleteToast text-[15px]">
            Advertisement successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  //   for delete Ads
  const deleteAds = async (id) => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/advertisement/advertisement/${id}`,
        { headers }
      );
      if (response.status) {
        setRenderData(!renderData);
        // toast.success("Ads Deleted");
        toast.warning(
          <div className="deleteToast text-[15px]">
            Advertisement successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };
  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  return (
    <AdsContext.Provider
      value={{
        data,
        postAds,
        getAds,
        updateAds,
        deleteAds,
        renderData,
        setData,
        setRenderData,
        uploadProgress,
      }}
    >
      {props.children}
    </AdsContext.Provider>
  );
};

export default AdsState;
