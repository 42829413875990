import React, { useContext, useEffect, useState } from "react";
import Comments from "./Comments";
import Syllabus from "./Syllabus";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const RightCard = (props) => {
  const { data, facultyName, blogdata } = props;
  const [toggleData, setToggleData] = useState(true);
  const { syllabusdata, syllabusPublicApi } = useContext(PublicApiContext);
  useEffect(() => {
    syllabusPublicApi();
    // eslint-disable-next-line
  }, []);

  const getFaculttName = (id) => {
    if (id === 1) {
      return "bba";
    } else if (id === 2) {
      return "bba-tt";
    } else {
      return "bca";
    }
  };
  const filterFaculty = syllabusdata.filter(
    (e) => getFaculttName(e.stream_name).toLocaleLowerCase() === facultyName
  );

  return (
    <>
      <div className="w-[100%] pb-10 md:mb-[400px] lg:mb-0">
        <div className="border-b pb-1">
          <h3 className="md:text-2xl text-xl text-[#041962] capitalize font-bold">
            {data.blog_title}
          </h3>
          {/* <p className="text-xl text-justify">
            (Bachelor of Business Administration)
          </p> */}
          <div className="flex justify-between my-2 cursor-pointer">
            <p className="ml-1">{data.author_name}</p>
            <div
              className="mr-5 underline text-sm font-semibold pt-1"
              onClick={() => setToggleData((p) => !p)}
            >
              {toggleData ? "View Syllabus " : "View Description"}
            </div>
          </div>
        </div>
        {toggleData && (
          <>
            <div className="!mt-5 text-justify flex flex-col gap-1">
              <div dangerouslySetInnerHTML={{ __html: data.blog_post }} />
            </div>

            <Comments blogdata={blogdata} />
          </>
        )}
        {!toggleData && (
          <Syllabus facultyName={facultyName} noteData={filterFaculty} />
        )}
      </div>
    </>
  );
};

export default RightCard;
