import React, { useEffect } from "react";
import AboutHero from "../About/common/AboutHero";
import ContactForm from "./ContactForm";

const Contact = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen-Contact";
  }, []);
  return (
    <>
      <AboutHero />
      <ContactForm />
    </>
  );
};

export default Contact;
