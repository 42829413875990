import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const MissionVision = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "about us" &&
      e.sub_category.toLowerCase() === "introduction"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 ? sortedData : [];

  const vision = finalData?.filter(
    (e) => e.sub_sub_category?.toLowerCase() === "vision"
  );
  const mission = finalData?.filter(
    (e) => e.sub_sub_category?.toLowerCase() === "mission"
  );

  return (
    <>
      <div className="w-[95%] xl:w-[80%] text-justify flex-col md:flex-row !mt-7 !mb-16 m-auto flex justify-between gap-10 items-center">
        <div className="min-h-[200px] md:h-[250px] max-h-[500px] overflow-hidden rounded-b-lg shadow-lg md:w-1/2 ">
          <div className="head bg-[#213C70] h-[60px] text-white flex items-center justify-center relative w-full">
            <div className="text-2xl font-semibold tracking-wide border-b px-1">
              Mission
            </div>
            <div className="absolute right-8 scale-75">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="14" r="14" fill="white" />
                <path
                  d="M14 19.9982L6 12.4996L8.66792 10L14 14.9991L19.3321 10L22 12.4996L14 20V19.9982Z"
                  fill="#041962"
                />
              </svg>
            </div>
          </div>
          <div className="md:p-5 p-4 md:px-10 tracking-wide lg:text-base text-slate-800">
            <div id="dynamiccourses">
              <div
                className="text-justify tracking-wide inline"
                dangerouslySetInnerHTML={{
                  __html: `${
                    mission[mission.length - 1]?.blog_post.length > 220
                      ? mission[mission.length - 1]?.blog_post.slice(0, 220) +
                        "..."
                      : mission[mission.length - 1]?.blog_post
                  }`,
                }}
              ></div>
              {mission[mission.length - 1]?.blog_post.length > 220 && (
                <Link
                  to={`/home/${mission[mission.length - 1].id}`}
                  className="underline text-[#041962]"
                >
                  view more
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="min-h-[200px] md:h-[250px] max-h-[500px] overflow-hidden rounded-b-lg shadow-lg md:w-1/2 ">
          <div className="head bg-[#213C70] h-[60px] text-white flex items-center justify-center relative w-full">
            <div className="text-2xl font-semibold tracking-wide border-b px-1">
              Vision
            </div>
            <div className="absolute left-8 scale-75">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14" cy="14" r="14" fill="white" />
                <path
                  d="M14 19.9982L6 12.4996L8.66792 10L14 14.9991L19.3321 10L22 12.4996L14 20V19.9982Z"
                  fill="#041962"
                />
              </svg>
            </div>
          </div>
          <div className="md:p-5 p-4 md:px-10 tracking-wide lg:text-base text-slate-800">
            <div id="dynamiccourses">
              <div
                className="text-justify tracking-wide inline"
                dangerouslySetInnerHTML={{
                  __html: `${
                    vision[vision.length - 1]?.blog_post.length > 220
                      ? vision[vision.length - 1]?.blog_post.slice(0, 220) +
                        "..."
                      : vision[vision.length - 1]?.blog_post
                  }`,
                }}
              ></div>
              {vision[vision.length - 1]?.blog_post.length > 220 && (
                <Link
                  to={`/home/${vision[vision.length - 1].id}`}
                  className="underline text-[#041962]"
                >
                  view more
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionVision;
