import React, { useEffect } from "react";
import AboutHero from "./common/AboutHero";
import Introduction from "./Introduction/Introduction";
import MissionVision from "./Introduction/MissionVision";
import BlankGap from "./common/BlankGap";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen-About";
  }, []);
  return (
    <>
      <AboutHero />
      <Introduction />
      <MissionVision />
      <BlankGap />
    </>
  );
};

export default About;
