import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Domain from "../Domain";
import BannerContext from "./BannerContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiCheckSquare } from "react-icons/fi";
import { BiErrorCircle } from "react-icons/bi";
import { MdDelete, MdOutlineFileUpload } from "react-icons/md";

const BannerState = (props) => {
  // to show formData
  let formData = new FormData();

  const DomainUrl = Domain();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        setUploadProgress(null);
      }, 1000);
    }
    if (uploadProgress === 0) {
      setUploadProgress(1);
    }
  }, [uploadProgress]);

  // fetch Banner data from backend
  const getFooter = async () => {
    try {
      const headers = await {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.get(
        `${DomainUrl}citizen_cms/api/v1/banner/banner`,
        {
          headers,
        }
      );
      const data = await response.data;
      setData(data);
    } catch (e) {}
  };

  // post Banner data
  const postBanner = async (data, file) => {
    formData.append("banner_image", file);
    formData.append("name", data.name);
    formData.append("slogan", data.slogan);
    formData.append("title", data.title);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.post(
        `${DomainUrl}citizen_cms/api/v1/banner/banner`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="text-[15px]">Banner successfully added</div>,
          { icon: <FiCheckSquare className="text-2xl" /> }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      // toast.error("Server Error");
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.slogan) {
          toast.error(
            <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        } else if (e.response.data.title) {
          toast.error(
            <div className="text-[14px]">{e.response.data.title[0]}</div>,
            { icon: <BiErrorCircle className="text-xl " /> }
          );
        }
      }
    }
  };

  // delete Banner data
  const deleteBanner = async (id) => {
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const response = await axios.delete(
        `${DomainUrl}citizen_cms/api/v1/banner/banner/${id}`,
        { headers }
      );
      if (response.status) {
        setRender(!render);
        // toast.success("Ads Deleted");
        toast.warning(
          <div className="deleteToast text-[15px]">
            Banner successfully deleted
          </div>,
          {
            icon: <MdDelete className="text-white text-3xl" />,
          }
        );
      } else {
        // toast.error("An error occured!");
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      // toast.error("Server Error");
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        toast.error(
          <div className="text-[14px]">{e.response.data.name[0]}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      }
    }
  };

  // update Banner data
  const updateBannerData = async (data, file, id) => {
    formData.append("banner_image", file);
    formData.append("name", data.name);
    formData.append("slogan", data.slogan);
    formData.append("title", data.title);
    try {
      const headers = await {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      };
      const res = await axios.patch(
        `${DomainUrl}citizen_cms/api/v1/banner/banner/${id}`,
        formData,
        { headers, onUploadProgress }
      );
      if (res.status) {
        toast.success(
          <div className="deleteToast text-[15px]">
            Banner successfully updated
          </div>,
          {
            icon: <MdOutlineFileUpload className="text-white text-3xl" />,
          }
        );
        setRender(!render);
      } else {
        toast.error(<div className="text-[14px]">An error occured!</div>, {
          icon: <BiErrorCircle className="text-xl " />,
        });
      }
    } catch (e) {
      if (e.response.data.detail) {
        toast.error(
          <div className="text-[14px]">{e.response.data.detail}</div>,
          {
            icon: <BiErrorCircle className="text-xl " />,
          }
        );
      } else {
        // toast.error("Server Error");
        if (e.response.data.name) {
          toast.error(
            <div className="text-[14px]">{e.response.data.name[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.slogan) {
          toast.error(
            <div className="text-[14px]">{e.response.data.slogan[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        } else if (e.response.data.title) {
          toast.error(
            <div className="text-[14px]">{e.response.data.title[0]}</div>,
            {
              icon: <BiErrorCircle className="text-xl " />,
            }
          );
        }
      }
    }
  };
  const onUploadProgress = (progressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  return (
    <>
      <BannerContext.Provider
        value={{
          getFooter,
          data,
          render,
          postBanner,
          deleteBanner,
          updateBannerData,
          uploadProgress,
        }}
      >
        {props.children}
      </BannerContext.Provider>
    </>
  );
};

export default BannerState;
