import React from "react";
import { Link } from "react-router-dom";

const TopNav = () => {
  return (
    <>
      <div className="w-full h-[30px] bg-[#2E2E2E] text-white">
        <ul className="flex items-center justify-end h-full gap-3 w-[96%] m-auto ">
          <li className="h-[85%] w-[1px] bg-[#C7C7C7]"></li>
          <li>
            <Link
              className="font-poppins tracking-wide text-[#C7C7C7] font-light text-sm"
              to="/extensions/news-and-events"
            >
              News & Events
            </Link>
          </li>
          <li className="h-[85%] w-[1px] bg-[#C7C7C7]"></li>
          <li>
            <Link
              className="font-poppins tracking-wide text-[#C7C7C7] font-light text-sm"
              to="/extensions/notice"
            >
              Notice
            </Link>
          </li>
          {/* <li className="h-[85%] w-[1px] bg-[#C7C7C7]"></li> */}
          {/* <li>
            <Link
              className="font-poppins tracking-wide text-[#C7C7C7] font-light text-sm flex items-center justify-center gap-[2px]"
              to="/admin/dashboard"
              target="blank"
            >
              <svg
                width="11"
                height="12"
                viewBox="0 0 11 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mb-[2px]"
              >
                <path
                  d="M9.59062 5.6H9.35V2.8C9.35 1.25627 8.05447 0 6.4625 0H4.5375C2.94552 0 1.65 1.25627 1.65 2.8V5.6H1.40937C1.21816 5.60074 1.03499 5.67472 0.899772 5.80584C0.764559 5.93696 0.68826 6.11458 0.6875 6.3L0.6875 13.3C0.6875 13.6845 1.01282 14 1.40937 14H9.59062C9.98717 14 10.3125 13.6845 10.3125 13.3V6.3C10.3117 6.11458 10.2354 5.93696 10.1002 5.80584C9.96501 5.67472 9.78184 5.60074 9.59062 5.6ZM3.575 2.8C3.575 2.28573 4.00716 1.86667 4.5375 1.86667H6.4625C6.99284 1.86667 7.425 2.28573 7.425 2.8V5.6H3.575V2.8Z"
                  fill="#C7C7C7"
                />
              </svg>
              Login
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default TopNav;
