// import image from "../../../../images/degree.png";
// import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Degree = () => {
  const [degree, setDegree] = useState(0);

  const {
    getblogPublicApi,
    blogdata,
    subSubCategoryPublicApi,
    subSubCategorydata,
  } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    subSubCategoryPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      e.sub_category.toLowerCase() === "slug-3"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData;
  const showData =
    finalData.length > 0 &&
    finalData.filter((e) => e.sub_category_key === degree);

  const subcatdata = subSubCategorydata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      e.sub_category_name.toLowerCase() === "slug-3"
  );

  return (
    finalData.length > 0 && (
      <div className="mt-14 pt-5 bg-[#E6E6E650] md:px-0">
        <div className="flex justify-center items-center flex-col md:my-7 my-2 px-4 md:px-0 py-5">
          <h3 className="font-aladin text-2xl text-[#041962]">
            Get Renowned PU Degree
          </h3>
          <div className="md:text-5xl text-3xl flex md:gap-[7px] gap-[2px] flex-row items-center justify-center font-abhaya">
            <div className="font-semibold">Courses At </div>
            <div>Citizen</div>
          </div>
          <p className="text-xl font-abhaya text-center md:text-start">
            Building Responsible Citizens Who can Innovate, Lead and Manage
          </p>
        </div>
        <div className="bg-white md:h-[350px] h-full w-full  px-2 md:px-0 ">
          <div className="lg:w-[80%] w-full h-full m-auto flex flex-col-reverse md:flex-row">
            <img
              src={showData[0]?.image}
              alt="author"
              className="h-[350px] max-w-[650px] md:w-full object-cover md:min-w-[350px] lg:min-w-[450px] w-full m-auto"
              loading="lazy"
            />
            <div className="md:m-6 my-4">
              <div className="border-b ">
                <ul className="flex md:gap-10 gap-3 text-base md:text-lg tracking-wide duration-150">
                  {subcatdata.map((e, index) => {
                    index === 0 && degree === 0 && setDegree(e.id);
                    return (
                      <li
                        key={e.id}
                        onClick={() => setDegree(e.id)}
                        className={`min-w-[80px] md:min-w-[100px] p-[2px] cursor-pointer uppercase hover:bg-[#041962] hover:!text-white text-black text-center ${
                          degree === e.id && "bg-[#041962] text-white"
                        }`}
                      >
                        {e.sub_category_key_name}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div id="dynamiccourses">
                <h3 className="text-xl font-semibold !text-start capitalize text-[#4A4A4A] tracking-wide mt-4 mb-1">
                  {showData[0]?.blog_title}
                </h3>
                <div
                  className="text-justify tracking-wide inline"
                  dangerouslySetInnerHTML={{
                    __html: `${
                      showData[0]?.blog_post.length > 320
                        ? showData[0]?.blog_post.slice(0, 320) + "..."
                        : showData[0]?.blog_post
                    }`,
                  }}
                ></div>
                {showData[0]?.blog_post.length > 320 && (
                  <Link
                    to={`/home/${showData[0].id}`}
                    className="underline text-[#041962]"
                  >
                    view more
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Degree;
