import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { Link } from "react-router-dom";

const Intro = () => {
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      e.sub_category.toLowerCase() === "slug-1"
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  const finalData = sortedData.length > 0 && sortedData[0];

  return (
    finalData && (
      <div className="w-full h-full bg-[#E6E6E650] -mt-12">
        <div className=" h-full py-14 pt-20 flex md:w-[800px] items-center w-[95%] m-auto justify-center mt-5">
          <div className="flex items-center justify-center flex-col-reverse  md:flex-row">
            <div className="md:w-1/2 w-full">
              <img
                src={finalData.image}
                alt="intro"
                className="h-[350px] md:w-[300px] w-full object-cover rounded-2xl shadow-lg"
                style={{ boxShadow: "-20px -25px 0 0 #99999950" }}
                loading="lazy"
              />
            </div>
            <div className="md:w-[65%] w-full flex flex-col gap-1 items-start mb-4">
              <h3 className="text-[#4A4A4A]  font-poppins font-semibold text-2xl tracking-wide border-b mb-1">
                {finalData.blog_title}
              </h3>
              <div className="text-justify tracking-wide ">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      finalData.blog_post?.length > 400 // 410
                        ? finalData.blog_post.slice(0, 400) + "..."
                        : finalData.blog_post,
                  }}
                ></div>
              </div>
              {finalData.blog_post?.length > 400 && (
                <Link
                  to={`/about-us/introduction`}
                  className="bg-transparent text-black p-[4px] mt-2 border tracking-wide hover:bg-[#041962] hover:text-white md:px-4 px-3 text-sm md:text-base duration-150 rounded"
                >
                  View More
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Intro;
