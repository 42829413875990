const Syllabus = (props) => {
  const { noteData, facultyName } = props;
  const groupedDataArray = [];

  noteData.forEach((current) => {
    const semType = current.sem_type;
    const existingSemData = groupedDataArray.find(
      (item) => item.sem === semType
    );

    if (!existingSemData) {
      groupedDataArray.push({ sem: semType, data: [current] });
    } else {
      existingSemData.data.push(current);
    }
  });

  function getOrdinalNumber(num) {
    const ordinals = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th"];

    if (num >= 1 && num <= ordinals.length) {
      return ordinals[num - 1];
    } else {
      return `${num}th`;
    }
  }

  return (
    <div className="pt-10 flex flex-col xl:flex-row gap-4">
      <div className="flex flex-col gap-3">
        {groupedDataArray.slice(0, 4).map((e, index) => {
          return (
            <div className="min-h-[20vh]" key={index}>
              <div className="w-full bg-[#041962] p-2 px-3 tracking-wide text-white font-semibold !uppercase">
                {facultyName} {getOrdinalNumber(e.sem)} Sem (Syllabus)
              </div>
              <table className="border w-full ">
                <thead className="border bg-[#041962] text-white">
                  <tr className="border">
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold md:w-[180px]">
                      Course Code
                    </th>
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold">
                      Subject Description
                    </th>
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold md:w-[180px]">
                      Credit Hours
                    </th>
                  </tr>
                </thead>
                <tbody className="border">
                  {e.data?.map((elm) => {
                    return (
                      <tr className="border" key={elm.id}>
                        <td className="border !text-sm md:text-base px-3 py-2 md:w-[180px]">
                          {elm.subject_code}
                        </td>
                        <td className="border !text-sm md:text-base px-3 py-2">
                          {elm.course_title}
                        </td>
                        <td className="border !text-sm md:text-base px-3 py-2 md:w-[180px]">
                          {elm.credit_hours}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-3">
        {groupedDataArray.slice(4, 8).map((e, index) => {
          return (
            <div className="min-h-[20vh]" key={index}>
              <div className="w-full bg-[#041962] p-2 px-3 tracking-wide text-white font-semibold !uppercase">
                {facultyName} {getOrdinalNumber(e.sem)} Sem (Syllabus)
              </div>
              <table className="border w-full ">
                <thead className="border bg-[#041962] text-white">
                  <tr className="border">
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold md:w-[180px]">
                      Course Code
                    </th>
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold">
                      Subject Description
                    </th>
                    <th className="border  !text-sm md:text-base px-3 py-2 font-semibold md:w-[180px]">
                      Credit Hours
                    </th>
                  </tr>
                </thead>
                <tbody className="border">
                  {e.data?.map((elm) => {
                    return (
                      <tr className="border" key={elm.id}>
                        <td className="border !text-sm md:text-base px-3 py-2 md:w-[180px]">
                          {elm.subject_code}
                        </td>
                        <td className="border !text-sm md:text-base px-3 py-2">
                          {elm.course_title}
                        </td>
                        <td className="border !text-sm md:text-base px-3 py-2 md:w-[180px]">
                          {elm.credit_hours}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Syllabus;
