import { TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import DashboardContext from "../../../context/dashboard/DashboardContext";
import "../css/dashboard.css";

const Dashboard = () => {
  const { DashBoarddata, getDashboardData, renderData } =
    useContext(DashboardContext);
  const { postdashboard } = useContext(DashboardContext);
  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line
  }, [renderData]);
  const [dataInp, setDataInp] = useState({
    Name: DashBoarddata[0] ? DashBoarddata[0].name : "",
    Slogan: DashBoarddata[0] ? DashBoarddata[0].slogan : "",
    Address: DashBoarddata[0] ? DashBoarddata[0].address : "",
    Email: DashBoarddata[0] ? DashBoarddata[0].email : "",
    phNumber: "",
    mobNumber: "",
  });

  const [logo, setLogo] = useState("");
  const [MobNumber, setMobNumber] = useState(
    DashBoarddata[0] ? DashBoarddata[0].mobile_no : ""
  );
  const [PhNumber, setPhNumber] = useState(
    DashBoarddata[0] ? DashBoarddata[0].phone_no_1 : "01-"
  );
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    msg: "",
    position: "",
  });

  useEffect(() => {
    if (DashBoarddata[0]) {
      setDataInp({
        Name: DashBoarddata[0] && DashBoarddata[0].name,
        Slogan: DashBoarddata[0] && DashBoarddata[0].slogan,
        Address: DashBoarddata[0] && DashBoarddata[0].address,
        Email: DashBoarddata[0] && DashBoarddata[0].email,
      });
      setPhNumber(DashBoarddata[0] && DashBoarddata[0].phone_no_1);
      setMobNumber(DashBoarddata[0] && DashBoarddata[0].mobile_no);
    }
  }, [DashBoarddata]);

  const handleChange = (e) => {
    setDataInp({ ...dataInp, [e.target.name]: e.target.value });
  };
  const handleMobNUmChange = (e) => {
    const Number = e.target.value;
    const result = Number.replace(/[^0-9 || +]/g, "");
    setMobNumber(result);
  };
  const handlePhNUmChange = (e) => {
    const Number = e.target.value;
    const result = Number.replace(/[^0-9 || -]/g, "");
    setPhNumber(result);
  };

  const handleClk = () => {
    const FinalData = { ...dataInp, phNumber: PhNumber, mobNumber: MobNumber };
    if (FinalData.Name.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter admin Name",
        position: "name",
      });
    } else if (FinalData.Slogan.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter slogan",
        position: "slogan",
      });
    } else if (FinalData.Address.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Address",
        position: "address",
      });
    } else if (FinalData.phNumber.length === 3) {
      setErrorMsg({
        status: true,
        msg: "Please Enter phone number",
        position: "PhNumber",
      });
    } else if (FinalData.phNumber.length > 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be less than 7",
        position: "PhNumber",
      });
    } else if (FinalData.phNumber.length < 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be atleast 7",
        position: "PhNumber",
      });
    } else if (MobNumber === "") {
      setErrorMsg({
        status: true,
        msg: "Please Enter Mobile number",
        position: "MobNumber",
      });
    } else if (FinalData.mobNumber.length > 15) {
      setErrorMsg({
        status: true,
        msg: "Number should be less than 14",
        position: "MobNumber",
      });
    } else if (FinalData.mobNumber.length < 10) {
      setErrorMsg({
        status: true,
        msg: "Number should be atleast 10",
        position: "MobNumber",
      });
    } else if (FinalData.Email.length === 0) {
      setErrorMsg({
        status: true,
        msg: "Please Enter Email address",
        position: "email",
      });
    } else if (!FinalData.Email.includes("@")) {
      setErrorMsg({
        status: true,
        msg: "Please enter valid email",
        position: "email",
      });
    } else if (logo === null || typeof logo !== "object") {
      setErrorMsg({
        status: true,
        msg: "Please choose logo",
        position: "logo",
      });
    } else {
      setErrorMsg({ status: false, msg: "", position: "" });
      postdashboard(FinalData, logo);
      localStorage.setItem("UserName", FinalData.Name);
      localStorage.setItem("Slogan", FinalData.Slogan);
      // setDataInp({ Name: '', Slogan: '', Address: '', PhNumber: "", MobNumber: '', Email: '' })
    }
  };

  const DashboardlogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  // var dt = new Date();

  return (
    <div className=" overflow-hidden ml-0 md:pl-[255px]  w-full p-8">
      <div className="overflow-hidden  m-auto w-full">
        <div className="box">
          <div className="left-box flex items-center justify-center flex-col">
            <div className="content">
              <p>
                {DashBoarddata.length > 0
                  ? DashBoarddata[DashBoarddata.length - 1].slogan
                  : ""}
              </p>
              {/* {localStorage.getItem("logo") ? (
                ) : (
                  ""
                  )} */}
              <img
                src={
                  DashBoarddata.length > 0
                    ? DashBoarddata[DashBoarddata.length - 1].logo
                    : ""
                }
                alt="img"
                loading="lazy"
                className="!w-[300px]"
              />
              <h2 className="mt-2 !text-xl text-[#5c5c5c]">Welcome To</h2>
              <h3 className="text-[#5c5c5c] !text-lg tracking-wider">
                {DashBoarddata.length > 0
                  ? DashBoarddata[DashBoarddata.length - 1].name
                  : ""}
              </h3>
            </div>
          </div>
          <div className="right-box">
            <div className="main">
              <div className="div div1 relative">
                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="Name"
                  label="Name"
                  value={dataInp.Name}
                  onChange={handleChange}
                  variant="outlined"
                />
                {errorMsg.position === "name" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}

                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="Slogan"
                  label="Slogan"
                  value={dataInp.Slogan}
                  onChange={handleChange}
                  variant="outlined"
                />
                {errorMsg.position === "slogan" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 right-[25%] ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="div div2 relative">
                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="Address"
                  label="Address"
                  value={dataInp.Address}
                  onChange={handleChange}
                  variant="outlined"
                />
                {errorMsg.position === "address" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}

                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="PhNumber"
                  label="Phone Number"
                  value={PhNumber}
                  onChange={handlePhNUmChange}
                  variant="outlined"
                />
                {errorMsg.position === "PhNumber" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 right-[15%] ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="div div3 relative">
                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="MobNumber"
                  label="Mobile Number"
                  value={MobNumber}
                  onChange={handleMobNUmChange}
                  variant="outlined"
                />
                {errorMsg.position === "MobNumber" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}

                <TextField
                  id="outlined-basic"
                  className="w-[45%]"
                  name="Email"
                  label="Email"
                  value={dataInp.Email}
                  onChange={handleChange}
                  variant="outlined"
                />
                {errorMsg.position === "email" ? (
                  <p className="text-red-500 text-xs absolute -bottom-4 right-[20%] ">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="div4 relative flex justify-between">
                <input
                  className="form-control form-control-lg text w-[45%] rounded-1 shadow-2xl bg-slate-200"
                  name="logo"
                  id="formFileLg"
                  type="file"
                  onChange={DashboardlogoChange}
                  style={{ fontSize: "16px", paddingTop: "10px" }}
                />
                {errorMsg.position === "logo" ? (
                  <p className="text-red-500 w-[96%] text-xs -bottom-4  absolute left-2">
                    **{errorMsg.msg}**
                  </p>
                ) : (
                  ""
                )}

                <button
                  id="button"
                  className="!py-[10px] !px[50px] w-[180px]"
                  onClick={handleClk}
                >
                  Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
