import React, { useContext, useEffect } from "react";
import EachNoticeCatd from "./EachNoticeCatd";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const AllNotice = () => {
  const { noticePublicApi, noticedata } = useContext(PublicApiContext);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    noticePublicApi();
    scrollToTop();
    document.title = "Citizen Notice";
    // eslint-disable-next-line
  }, []);
  const reversedNoticedata = noticedata.sort((a, b) => b.id - a.id);
  return (
    <>
      <div className="min-h-[40vh] w-full flex justify-center mt-8 mb-14">
        <div className="w-[90%] relative flex justify-center items-center flex-col float-right h-full">
          <h2 className="my-7 mb-10 md:mb-7 text-4xl  font-semibold border-b  font-abhaya text-[#041962] tracking-wide">
            Notice
          </h2>
          <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 h-full">
            {reversedNoticedata.map((e) => (
              <EachNoticeCatd data={e} key={e.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllNotice;
