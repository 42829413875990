import React, { useContext, useEffect } from "react";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";
import { useParams } from "react-router-dom";
import EachPostSlug from "./EachPostSlug";

const IdSlug = () => {
  const data = useParams().id;
  const { getOneblogPublicApi, oneblogdata } = useContext(PublicApiContext);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    getOneblogPublicApi(data);
    scrollToTop();
    document.title = "Citizen College";
    // eslint-disable-next-line
  }, []);

  return oneblogdata[0] && <EachPostSlug data={oneblogdata[0]} />;
};

export default IdSlug;
