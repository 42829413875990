import React, { useContext, useEffect } from "react";
import EachNewsCards from "./EachNewsCards";
import PublicApiContext from "../../../../context/PublicAPI/PublicApiContext";

const AllNewsEvent = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  const { getblogPublicApi, blogdata } = useContext(PublicApiContext);
  useEffect(() => {
    getblogPublicApi();
    document.title = "Citizen-Events";
    // eslint-disable-next-line
  }, []);
  const data = blogdata.filter(
    (e) =>
      e.category_name.toLowerCase() === "home" &&
      (e.sub_category.toLowerCase() === "news&event" ||
        e.sub_category.toLowerCase() === "slug-4")
  );
  const sortedData = data.sort((a, b) => b.id - a.id);
  return (
    <div className=" min-h-[40vh] w-full flex justify-center mt-10 mb-14">
      <div className="w-[90%] relative flex justify-center items-center flex-col float-right h-full">
        <h2 className="my-7 mb-10 md:mb-7 text-4xl font-semibold border-b  font-abhaya text-[#041962] tracking-wide">
          News & Events
        </h2>
        <div className="grid grid-col-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 h-full">
          {sortedData.length > 0 &&
            sortedData.map((e) => {
              return <EachNewsCards key={e.id} elm={e} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default AllNewsEvent;
