import React, { useEffect } from "react";
import AboutHero from "./common/AboutHero";
import BlankGap from "./common/BlankGap";
import SirMsg from "./message/SirMsg";

const MessageFromPri = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
    document.title = "Citizen-Message";
  }, []);
  return (
    <>
      <AboutHero />
      <SirMsg />
      <BlankGap />
    </>
  );
};

export default MessageFromPri;
