import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { MdDelete, MdEdit } from "react-icons/md";
import AdsContext from "../../../context/Advertisement/AdsContext";
import AddsModal from "../Modals/AddsModal";
import { Pagination } from "@mui/material";
import DeleteAlert from "../../common/DeleteAlert";
import LoginContext from "../../../context/Login/LoginContext";

const Adds = () => {
  const [OpenModal, setOpenModal] = useState(false);
  const { getAds, deleteAds, renderData, data, uploadProgress } =
    useContext(AdsContext);
  const [btnData, setBtnData] = useState({ name: "", id: "" });
  const [editableData, setEditableData] = useState([]);

  const AddBtnCLk = (name, id) => {
    setOpenModal(true);
    setBtnData({ name: name, id: id });
  };

  // for delete alert logic
  const [open, setOpen] = useState(false);
  const deleteBtnClk = (id, name) => {
    setOpen(true);
    setBtnData({ id });
  };
  const editBtnClk = (id) => {
    setBtnData({ name: "updateBtn", id: id });
    setOpenModal(true);
    const filterdata = data.filter((e) => e.id === id);
    setEditableData(filterdata);
  };

  useEffect(() => {
    getAds();
    // eslint-disable-next-line
  }, [renderData]);
  const paginationValue = 10;
  const [pagValue, setPagValue] = useState(1);
  const totalData = data.length;
  const totalPage = Math.ceil(totalData / paginationValue);

  // search data Logic
  const { searchValue } = useContext(LoginContext);
  const searchData = data.filter((e) =>
    e.name.toLowerCase().includes(searchValue.toLocaleLowerCase())
  );

  return (
    <>
      <div className="h-full overflow-y-auto mt-5 md:ml-[270px] relative">
        <div id="table" className="w-[95%] pt-[45px] relative m-auto">
          <button
            className=" addbutton absolute top-0 right-0 rounded-[5px] border px-3 py-2 flex items-center justify-center"
            onClick={AddBtnCLk}
          >
            Add Advertisement <BsPlusLg className="mt-0 ml-1 text-[11px]" />{" "}
          </button>
          <table
            border="1"
            className="border mt-[10px] border-b-0"
            style={{ borderCollapse: "collapse", width: "100%" }}
          >
            <thead>
              <tr
                className="border text-center"
                style={{ height: "50px", fontSize: "14px" }}
              >
                <th className="border p-1" style={{ width: "10%" }}>
                  S.N
                </th>
                <th className="border p-1" style={{ width: "40%" }}>
                  Title
                </th>
                <th className="border p-1" style={{ width: "40%" }}>
                  Image
                </th>
                {/* <th className='border p-1' style={{ width: '100px' }}>Active/Inactive</th> */}
                <th className="border p-1" style={{ width: "20%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {searchValue.length < 2 ? (
                <>
                  {data
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          className="border text-center"
                          style={{ height: "40px", fontSize: "14px" }}
                          key={e.id}
                        >
                          <td className="border p-1" style={{ width: "10%" }}>
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border p-1 " style={{ width: "40%" }}>
                            {e.name}
                          </td>
                          <td className="border p-1 " style={{ width: "40%" }}>
                            {" "}
                            <img
                              src={e.banner_image}
                              alt=""
                              className="m-auto h-[30px] w-[30px] rounded-full object-cover"
                              loading="lazy"
                            />{" "}
                          </td>
                          {/* <td className='border p-1 ' style={{ width: '100px' }}>
                                <AiFillCheckCircle className='text-green-600 m-auto text-xl'/>
                            </td> */}
                          <td className="flex items-center justify-center p-1 relative !w-[100px] m-auto">
                            <button className="edit actionIcons left-5">
                              <MdEdit
                                className="text-xl text-white icons"
                                onClick={() => editBtnClk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons right-5 ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {searchData
                    .slice(
                      (pagValue - 1) * paginationValue,
                      pagValue * paginationValue
                    )
                    .map((e, index) => {
                      return (
                        <tr
                          className="border text-center"
                          style={{ height: "40px", fontSize: "14px" }}
                          key={e.id}
                        >
                          <td className="border p-1" style={{ width: "10%" }}>
                            {index + 1 + (pagValue - 1) * paginationValue}
                          </td>
                          <td className="border p-1 " style={{ width: "40%" }}>
                            {e.name}
                          </td>
                          <td className="border p-1 " style={{ width: "40%" }}>
                            {" "}
                            <img
                              src={e.banner_image}
                              alt=""
                              className="m-auto h-[30px] w-[30px] rounded-full object-cover"
                              loading="lazy"
                            />{" "}
                          </td>
                          <td className="flex items-center justify-center p-1 relative !w-[100px] m-auto">
                            <button className="edit actionIcons left-5">
                              <MdEdit
                                className="text-xl text-white icons"
                                onClick={() => editBtnClk(e.id)}
                              />
                            </button>
                            <button className="delete actionIcons right-5 ">
                              <MdDelete
                                className="text-xl text-white icons"
                                onClick={() => deleteBtnClk(e.id)}
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {open ? (
            <DeleteAlert
              open={open}
              setOpen={setOpen}
              id={btnData.id}
              deleteFun={deleteAds}
            />
          ) : (
            <></>
          )}
          {data.length > 4 ? (
            <Pagination
              className="flex w-full justify-end itcem-center"
              count={totalPage}
              variant="outlined"
              shape="rounded"
              onChange={(e, value) => setPagValue(value)}
            />
          ) : (
            <></>
          )}
        </div>
        {OpenModal ? (
          <AddsModal
            OpenModal={OpenModal}
            setOpenModal={setOpenModal}
            btnData={btnData}
            editableData={editableData}
            uploaded={uploadProgress}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Adds;
